import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Popconfirm, Select, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import { useTranslation } from 'react-i18next';
import { Printer } from '../../../interfaces/IPrinterManagement';
import i18n from '../../../i18n';

export interface IToken {
  deviceToken: string;
}

export interface PrinterDetails {
  printerDetails: {
    printerIsConnected: boolean;
    printMode: string;
    labelLengthInDots: number;
    numberOfFormatsInReceiveBuffer: number;
    labelsRemainingInBatch: number;
    isPartialFormatInProgress: boolean;
    isHeadCold: boolean;
    isHeadOpen: boolean;
    isHeadTooHot: boolean;
    isPaperOut: boolean;
    isRibbonOut: boolean;
    isReceiveBufferFull: boolean;
    isPaused: boolean;
    isReadyToPrint: boolean;
  };
}

const PrinterManagement: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const { onSetPage } = props;
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [printerData, setPrinterData] = useState<Printer>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null);

  const pageData: IPageData = {
    title: t('Printer Management'),
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    PrinterFilter();
  }, [i18n.language]);

  const printModeOptions: IDropdownItem[] = [
    { id: 'mode1', name: 'Mode 1' },
    { id: 'mode2', name: 'Mode 2' },
    { id: 'mode3', name: 'Mode 3' },
  ];

  const renderValue = (value: any) => {
    if (typeof value === 'boolean') {
      return <Checkbox checked={value} disabled />;
    }
    return value;
  };

  const DetailsColumn = [
    {
      title: t('key'),
      key: 'Key',
      dataIndex: 'Key',
    },
    {
      title: t('value'),
      key: 'Value',
      dataIndex: 'Value',
      render: (value: any, record: any) => {
        if (record.Key === 'printMode') {
          return (
            <Select
              style={{ width: '100%'}}
              value={value}
              disabled
            >
              {printModeOptions.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (Array.isArray(value)) {
          return (
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              disabled
              value={value}
            >
              {value.map((item: any, index: number) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return renderValue(value);
      },
    },
  ];

  const columns: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      isSorting:true,
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    {
      title: t('Printer Name'),
      key: 'printerName',
      isSorting:true,
      placeHolder: t('Printer Name'),
      inputType: 'input',
    },
    {
      title: t('Printer Model'),
      key: 'printerModel',
      isSorting:true,
      placeHolder: t('Printer Model'),
      inputType: 'dropdown',
      dropdownItem: printModeOptions,
      width: 1000,
    },
    {
      title: t('Printer Code'),
      key: 'printerCode',
      isSorting:true,
      placeHolder: t('Printer Code'),
      inputType: 'input',
      width: 1000,
    },
    {
      title: t('Ip Address'),
      key: 'printerIpAddress',
      placeHolder: t('Ip Address'),
      inputType: 'input',
    },
    {
      title: t('Printer Port'),
      key: 'printerPort',
      placeHolder: t('Printer Port'),
      inputType: 'input',
    },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: 'operations',
      render: (order, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={t('delete_confirm')}
              onConfirm={() => {
                DeleteDevice(record.printerId);
              }}
              okText={t('yes')}
              cancelText={t('no')}
            >
            <Tooltip title={t('delete')}>
              <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'  }}
                  onClick={(e) => e.stopPropagation()}
                >
                </Button>
            </Tooltip>
          
            </Popconfirm>
            <Tooltip title={t('update')}>
              <Button
                size='small'
                icon={<EditOutlined />}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'}}
                onClick={() => {
                  window.location.replace(`/admin/update-printer/${record.printerId}`);
                }}
              >
              </Button>
            </Tooltip>
            
            <Tooltip title={t('details')}>
            <Button
              size='small'
              icon={<EyeOutlined />}
              style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              onClick={() => {
                setSelectedPrinter(record);
                setIsModalVisible(true);
              }}
            >
            </Button>
            </Tooltip>
          
          </Space>
        </div>
      ),
    },
  ];

  const PrinterFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';
    
    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      printerName: e?.printerName,
      printerIpAddress: e?.printerIpAddress,
      companyId: e?.companyId,
      companyName: e?.companyName,
      printerModel: e?.printerModel,
      printerPort: e?.printerPort,
    };

    HTTPService.POST(`/Printer/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }

      if (response.status === 200) {
        setPrinterData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DeleteDevice = (id) => {
    HTTPService.DELETE(`/Printer/deletePrinter?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(response.data);
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedPrinter(null);
  };

  const printerDetailsToTableData = (printer: Printer) => {
    if (!printer.printerDetails) {
      return [];
    }
    return Object.entries(JSON.parse(printer.printerDetails)).map(([key, value]) => ({
      Key: key,
      Value: typeof value === 'boolean' ? value : JSON.stringify(value),
    }));
  };

  const modalWidth = window.innerWidth < 768 ? '90%' : 650; 

  return (
    <>
      <div className='row'>
        <div
          className='col-md-4 mb-3'
          style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
        >
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-printer')}
          >
            {t('Add New Printer')}
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <AltisDataGrid
            data={printerData?.data}
            total={printerData?.count}
            columns={columns}
            onChange={PrinterFilter}
          />
        </div>
      </div>
      <Modal
        title={<span style={{ fontWeight:'bold' }}>{t('details')}</span>}
        visible={isModalVisible}
        bodyStyle={{ padding: 15, maxHeight: '70vh', overflowY: 'auto' }}
        width={modalWidth} // Responsive width
        footer={null}
        onCancel={handleCancel}
      >
        <div style={{ overflowX: 'auto' }}>
          <Table
            key={"kfgjfdkgnmd"}
            pagination={false}
            columns={DetailsColumn}
            dataSource={selectedPrinter ? printerDetailsToTableData(selectedPrinter) : []}
            rowKey="Key"
            scroll={{ x: 'max-content' }} // Horizontal scroll if needed
          />
        </div>
      </Modal>
    </>
  );
};

export default PrinterManagement;
