import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, UnorderedListOutlined} from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Image, message, Modal, notification, Pagination, Popconfirm, Row, Select, SelectProps, Space, Spin, Table, Tag, Tooltip} from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useRef, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { IInventory } from '../../../interfaces/IItemData';
import i18n from '../../../i18n';
import { IFilterOptions } from '../../../interfaces/IFilterOptions';
import moment from 'moment';

const InventoryReportManagement: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  // const [inventoryData, setInventoryData] = useState<IInventory>();
  const [filters, setFilters] = useState({ brands: [], colors: [] });
  const [inventoryData, setInventoryData] = useState({ data: [], count: 0 });
  const [createDate, setCreateDate] = useState(null);
  const { Panel } = Collapse;
  const dataGridRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    status: [],
    campus: [],
    locations: [],
    brand: [],
    supplier: [],
    type: [],
    department: []
  });
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ brand: [], department: [], status: [], locations: [], category: [], supplier: [], campus: [], type: [],countReport:[] });

  const pageData: IPageData = {
    title: t('report'),
    loaded: true,
  };


  useEffect(() => {
    onSetPage(pageData);
    InventoryReportFilter();
    InventoryFilter();
  }, [i18n.language]);


  const handleSelectAll = (e) => {
    var details = { ...inventoryData };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
    } else {
      details.data.map((row) => (row.isSelected = false));
    }

    setInventoryData(details);
  };

  const handleRowSelect = (e, index) => {
    var newObject = { ...inventoryData };
    newObject.data[index].isSelected = e.target.checked;
    setInventoryData(newObject);

    const selectedRows = newObject.data.filter(row => row.isSelected);
  };

  const excelDownload = () => {
    const selectedRows = inventoryData.data.filter(row => row.isSelected);
    if (selectedRows.length === 0) {
        toast.error(t('NoRows'));
        return;
    }


    const selectedData = selectedRows.map(row => ({
      inventoryName: row.inventoryName,
      barcode: row.barcode,
      typeName: row.typeName,
      categoryName: row.categoryName,
      locationName: row.locationName,
      departmentName: row.departmentName,
      supplierName: row.supplierName,
      brandName: row.brandName,
    }));

    HTTPService.POSTblobExcel('/Report/ExcelDownload', selectedData)
      .then(() => {
        toast.success(t('excelSuccess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('error'));
      });
  };

  const pdfDownload = () => {
    const selectedRows = inventoryData.data.filter(row => row.isSelected);

    if (selectedRows.length === 0) {
        toast.error(t('NoRows'));
        return;
    }


    const selectedData = selectedRows.map(row => ({
      inventoryName: row.inventoryName,
      barcode: row.barcode,
      typeName: row.typeName,
      categoryName: row.categoryName,
      locationName: row.locationName,
      departmentName: row.departmentName,
      supplierName: row.supplierName,
      //brandName: row.brandName,
    }));
    HTTPService.POSTblob('/Report/PdfDownload', selectedData)
      .then(() => {
        toast.success(t('pdfSuccess'));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('error'));
      });
  };


  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            inventoryData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>

      ),
    },
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'nofilter'
    }] : []),
    { title: t('inventoryName'), isSorting:true, key: 'inventoryName', placeHolder: t('inventoryName'), inputType: 'nofilter' },
    { title: t('barcode'), isSorting:true, key: 'barcode', placeHolder: t('barcode'), inputType: 'nofilter' },
    { title: t('item_type'), isSorting:true, key: 'typeName', placeHolder: t('item_type'), inputType: 'nofilter', width: 500 },
    { title: t('item_group'), isSorting:true, key: 'categoryName', placeHolder: t('item_group'), inputType: 'nofilter', width: 500 },
    { title: t('location'), isSorting:true, key: 'locationName', placeHolder: t('location'), inputType: 'nofilter' },
    { title: t('Department'), isSorting:true, key: 'departmentName', placeHolder: t('Department'), inputType: 'nofilter' },
    { title: t('supplier'), isSorting:true, key: 'supplierName', placeHolder: t('supplier'), inputType: 'nofilter' },
    { title: t('brand'), isSorting:true, key: 'brandName', placeHolder: t('brand'), inputType: 'nofilter' },
    ...(userInfo.role === UserRole.Personel || userInfo.role === UserRole.Operator ? [{
      title: t('responsible'),  isSorting:true,key: 'responsible', placeHolder: t('responsible'), inputType: 'nofilter'
    }] : []),
  ];


  const InventoryReportFilter = (e?) => {
    const body = {
      category: e?.category,
      campus: e?.campus,
      type: e?.type
    };

    HTTPService.POST(`Report/postfilters`, body).then((response) => {
      if (response.status === 200) {
        console.log(response.data)
        setFilterOptions(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  // const DeleteInventory = (id) => {
  //   HTTPService.DELETE(`/Inventory/deleteInventory?Id=${id}`).then((response) => {
  //     if (response.status === 200) {
  //       toast.success(t(response.data));
  //       InventoryFilter();
  //     } else {
  //       toast.error(t(response.data));
  //     }
  //   });
  // };

  const InventoryFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      barcode: e?.barcode,
      departmentName: e?.departmentName,
      inventoryName: e?.inventoryName,
      inventoryStatusName: e?.inventoryStatusName,
      typeName: e?.typeName,
      debtFirstName: e?.debtFirstName,
      debtLastName: e?.debtLastName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      itemName: e?.itemName,
      itemGroup: e?.itemGroup,
      locationName: e?.locationName,
      categoryName: e?.categoryName,
      debbit: e?.debbit,
      responsible: e?.responsible,
      isPrinted: e?.isPrinted,
      brand: e?.brand,
      location: e?.location,
      campus: e?.campus,
      status: e?.status,
      category: e?.category,
      type: e?.type,
      createDate: e?.startDate ? moment(e.startDate).toISOString() : undefined
    };

    HTTPService.POST(`/Report/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setInventoryData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const handleSearch = () => {
    const filterData = {
      ...filters,
      pageSize: 10,
      page: 1,
      brand: selectedFilters.brand,
      location: selectedFilters.locations,
      campus: selectedFilters.campus,
      department:selectedFilters.department,
      status: selectedFilters.status,
      category: selectedFilters.category,
      type: selectedFilters.type
    };

    InventoryFilter(filterData);
  };

  const handleDateChange = (dates) => {
    if (!dates) {
      setFilters((prev) => ({ ...prev, startDate: null, endDate: null }));
      setCreateDate({ startDate: null, endDate: null });
      return;
    }

    const [startDate, endDate] = dates;
    setFilters((prev) => ({ ...prev, startDate, endDate }));
    setCreateDate({ startDate, endDate });
  };


  const handleFilterChange = (filterType: keyof typeof selectedFilters, id: number) => {
    const newSelectedFilters = {
      ...selectedFilters,
      [filterType]: selectedFilters[filterType].includes(id)
        ? selectedFilters[filterType].filter(item => item !== id)
        : [...selectedFilters[filterType], id]
    };

    setSelectedFilters(newSelectedFilters);

    if (filterType === 'category') {
      InventoryReportFilter({ category: newSelectedFilters.category });
    }

    if (filterType === 'campus') {
      InventoryReportFilter({ campus: newSelectedFilters.campus });
    }

  };

  const clearFilters = () => {
    const initialFilters = {
      category: [],
      status: [],
      campus: [],
      locations: [],
      brand: [],
      supplier: [],
      type: [],
      department: []
    };

    setSelectedFilters(initialFilters);


    InventoryFilter({
      pageSize: 10,
      page: 1,
      createDate: null
    });
  };


  return (
    <>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={8} md={6} lg={4} xl={4} style={{ paddingLeft: '8px' }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
            padding: '5px 10px',
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            backgroundColor: 'white',
            marginTop: '40px'
          }}>
            <span style={{
              flexGrow: 1,
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#333'
            }}>
              {t('noFilter')}
            </span>
            <CloseOutlined
              onClick={clearFilters}
              style={{ cursor: 'pointer', color: 'black', fontSize: '16px' }}
            />
          </div>
          <Collapse style={{
            width: '100%',
            maxWidth: '220px',
            marginTop: '20px',
            border: 'none'
          }}>
            <Collapse.Panel header={t('category')} key="2">
              {filterOptions.category.map(option => (
                <div key={option.categoryId}>
                  <Checkbox
                    value={option.categoryId}
                    checked={selectedFilters.category.includes(option.categoryId)}
                    onChange={() => handleFilterChange('category', option.categoryId)}
                  >
                    {option.categoryName}
                  </Checkbox>
                </div>
              ))}
            </Collapse.Panel>

            <Collapse.Panel header={t('item_type')} key="3">
              {filterOptions.type
                .filter(option =>
                  selectedFilters.category.length === 0 ||
                  selectedFilters.category.includes(option.categoryId)
                )
                .map(option => (
                  <div key={option.typeId}>
                    <Checkbox
                      value={option.typeId}
                      checked={selectedFilters.type.includes(option.typeId)}
                      onChange={() => handleFilterChange('type', option.typeId)}
                    >
                      {option.typeName}
                    </Checkbox>
                  </div>
                ))}
            </Collapse.Panel>
            <Collapse.Panel header={t('InventoryStatus')} key="4">
              {filterOptions.status.map(option => (
                <div key={option.inventoryStatusId}>
                  <Checkbox
                    checked={selectedFilters.status.includes(option.inventoryStatusId)}
                    value={option.inventoryStatusId}
                    onChange={() => handleFilterChange('status', option.inventoryStatusId)}
                  >
                    {option.inventoryStatusName}
                  </Checkbox>
                </div>
              ))}
            </Collapse.Panel>
            <Collapse.Panel header={t('campus')} key="5">
              {filterOptions.campus.map(option => (
                <div key={option.campusId}>
                  <Checkbox
                    checked={selectedFilters.campus.includes(option.campusId)}
                    value={option.campusId}
                    onChange={() => handleFilterChange('campus', option.campusId)}
                  >
                    {option.campusName}
                  </Checkbox>
                </div>
              ))}
            </Collapse.Panel>
            <Collapse.Panel header={t('location')} key="6">
              {filterOptions.locations
                .filter(option =>
                  !selectedFilters.campus.length || selectedFilters.campus.includes(option.campusId)
                )
                .map(option => (
                  <div key={option.locationId}>
                    <Checkbox
                      checked={selectedFilters.locations.includes(option.locationId)}
                      value={option.locationId}
                      onChange={() => handleFilterChange('locations', option.locationId)}
                    >
                      {option.locationName}
                    </Checkbox>
                  </div>
                ))}
            </Collapse.Panel>
            <Collapse.Panel header={t('Department')} key="7">
              {filterOptions.department.map(option => (
                <div key={option.departmentId}>
                  <Checkbox
                    name={`departmentName-${option.departmentName}`}
                    value={option.departmentId}
                    checked={selectedFilters.department.includes(option.departmentId)}
                    onChange={() => handleFilterChange('department', option.departmentId)}
                  >
                    {option.departmentName}
                  </Checkbox>
                </div>
              ))}
            </Collapse.Panel>

            <Collapse.Panel header={t('brand')} key="8">
              {filterOptions.brand.map(option => (
                <div key={option.brandId}>
                  <Checkbox
                    name={`brandName-${option.brandName}`}
                    value={option.brandId}
                    checked={selectedFilters.brand.includes(option.brandId)}
                    onChange={() => handleFilterChange('brand', option.brandId)}
                  >
                    {option.brandName}
                  </Checkbox>
                </div>
              ))}
            </Collapse.Panel>
            <Collapse.Panel header={t('supplier')} key="9">
              {filterOptions.supplier.map(option => (
                <div key={option.supplierId}>
                  <Checkbox
                    value={option.supplierId}
                    checked={selectedFilters.supplier.includes(option.supplierId)}
                    onChange={() => handleFilterChange('supplier', option.supplierId)}
                  >
                    {option.supplierName}
                  </Checkbox>
                </div>
              ))}

            </Collapse.Panel>

            <Panel header={t('date')} key="10">
              <DatePicker.RangePicker onChange={handleDateChange} format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Panel>
          </Collapse>
          <Button type="primary" style={{ marginTop: '10px', width: '100%' }} onClick={handleSearch}>
            {t('search')}
          </Button>
        </Col>

        <Col xs={24} sm={16} md={18} lg={20} xl={20} style={{ marginTop: '10px', paddingLeft: '8px' }}>
          <Button type="primary" onClick={excelDownload} style={{ marginTop: '-40px', width: '200px', marginRight: '10px' }}>
            {t('Excel')}
          </Button>
          <Button type="primary" onClick={pdfDownload} style={{ marginTop: '-40px', width: '200px' }}>
            {t('PDF')}
          </Button>
          <AltisDataGrid
            ref={dataGridRef}
            columns={columns}
            onChange={InventoryFilter}
            data={inventoryData?.data}
            total={inventoryData?.count}
          />
        </Col>
      </Row>
    </>
  );



};

export default InventoryReportManagement;
