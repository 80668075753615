import React, { useEffect, useState } from 'react';
import { Route, Redirect, matchPath } from 'react-router-dom';
import { IRoute, routes } from './routes';
import './assets/sass/App.scss';
import './assets/sass/styles.scss';
import { store } from "./redux/store";
import { useTranslation } from 'react-i18next';
import i18n from './i18n.js';
import { StateLoader } from './redux/StateLoader';

const App: React.FC = () => {
    const { t } = useTranslation(); 
    const user = store.getState().user;
    const [route, setRoute] = useState<string>(undefined);

    useEffect(() => {
        const store = new StateLoader();
        i18n.changeLanguage(store.getLanguage() ?? "tr");
        let layout = "/" + window.location.pathname.split('/')[1];
        
        if (haveUser()) {
            if (pathIsExist()) {
                if (layout === "/") {
                    setRoute('/admin/dashboard');
                } else {
                    setRoute(window.location.pathname);
                }
            } else {
                setRoute('/admin/dashboard');
            }
        } else {
            if(layout === "/public") {
                setRoute(window.location.pathname);
            } else {
                setRoute('/public/sign-in');
            }
        }
    }, []);

    const pathIsExist = () => {
        let result = false;
        let splittedURL = window.location.pathname.split('/');
        let currentLayout = "/" + splittedURL[1];
        let currentPath = "/" + splittedURL[2];
        let selectedRoute = [];
        if (currentLayout.length < 1 || currentLayout === '/') {
            return true;
        }
        routes.forEach(route => {
            if (currentLayout.includes(route.path)) {
                selectedRoute = route.children;
            }
        });
        if (selectedRoute.length > 0) {
            selectedRoute.forEach((item: IRoute) => {
                if (item.path.includes(currentPath)) {
                    result = true;
                }
            });
        } else {
            result = false;
        }

        return result;
    }

    const haveUser = () => {
        return Object.keys(user).length > 0;
    }

    return (
        <div className='App'>
            {routes.map((route, i) => (
                <Route key={i} path={route.path} component={route.component} />
            ))}
            {route && <Redirect exact to={route} />}
        </div>
    );
};

export default App;

