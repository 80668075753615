import React from 'react';

import WrappedForm from './SignInForm';

import './SignIn.scss';
import { t } from 'i18next';

const PageSignIn = () => {
    return (
        <>
            <div className='title-block'>
                <h5 className='form-title'>{t('sign')}</h5>
            </div>
            <WrappedForm/>
        </>
    );
};

export default PageSignIn;
