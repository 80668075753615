import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Select, message, Image, SelectProps, Popconfirm, Space, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { ICompany } from '../../../interfaces/ICompany';
import { IItemData } from '../../../interfaces/IItemData';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/es/upload/interface';
import { ILocationData } from '../../../interfaces/ILocationData';
import { ICampusData } from '../../../interfaces/ICampusData';
import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import AddLocation from '../LocationManagement/AddLocation';
import { ICategoryData } from '../../../interfaces/ICategoryData';
import { ITypeData } from '../../../interfaces/ITypeData';
import { IDepartmentData } from '../../../interfaces/IDepartmentData';
import { ISupplierData } from '../../../interfaces/ISupplierData';
import { IBrandData } from '../../../interfaces/IBrandData';
import { API_URL } from '../../../utils/Constants';
import { IInventoryStatusData } from '../../../interfaces/IInventoryStatusData';
import AddCategory from '../CategoryManagement/AddCategory';
import AddType from '../TypeManagement/AddType';
import AddDepartment from '../DepartmentManagement/AddDepartment';
import AddSupplier from '../SupplierManagement/AddSupplier';
import AddBrand from '../BrandManagement/AddBrand';
import AddInventoryStatus from '../InventoryStatus/AddInventoryStatus';
import AddCampus from '../CampusManagement/AddCampus';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';


const UpdateInventory: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { inventoryId } = useParams<{ inventoryId: string }>();
  const [itemData, setItemData] = useState<IItemData>({
    inventoryName: "",
    serialNo: "",
    location: "",
    itemType: 0,
    locationCity: "",
    responsibleFirstname: "",
    responsibleLastname: "",
    responsibleTelephone: "",
    responsibleEmail: "",
    barcode: "",
    imageUrl: "",
  });

  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  const [locationData, setLocationData] = useState<ILocationData[]>([]);
  const [inventoryStatusData, setInventoryStatusData] = useState<IInventoryStatusData[]>([]);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [categoryData, setCategoryData] = useState<ICategoryData[]>([]);
  const [supplierData, setSupplierData] = useState<ISupplierData[]>([]);
  const [departmentData, setDepartmentData] = useState<IDepartmentData[]>([]);
  const [campusData, setCampusData] = useState<ICampusData[]>([]);
  const [typeData, setTypeData] = useState<ITypeData[]>([]);
  const [brandData, setBrandData] = useState<IBrandData[]>([]);
  const [isModalVisibleCampus, setIsModalVisibleCampus] = useState(false);
  const [isModalVisibleInventoryStatus, setIsModalVisibleInventoryStatus] = useState(false);
  const [isModalVisibleCategory, setIsModalVisibleCategory] = useState(false);
  const [isModalVisibleLocation, setIsModalVisibleLocation] = useState(false);
  const [isModalVisibleSupplier, setIsModalVisibleSupplier] = useState(false);
  const [isModalVisibleType, setIsModalVisibleType] = useState(false);
  const [isModalVisibleDepartment, setIsModalVisibleDepartment] = useState(false);
  const [isModalVisibleBrand, setIsModalVisibleBrand] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState(itemData?.locationTBL?.campusTBL.campusId || "");
  const [selectedLocation, setSelectedLocation] = useState(itemData?.locationId || "");
  const [selectedType, setSelectedType] = useState(itemData?.typeId || "");
  const [selectedCategory, setSelectedCategory] = useState(itemData?.itemTypeTBL?.categoryId || "");
  const [selectedSupplier, setSelectedSupplier] = useState(itemData?.supplierId || "");
  const [selectedDepartment, setSelectedDepartment] = useState(itemData?.departmentId || "");
  const [selectedStatus, setSelectedStatus] = useState(itemData?.inventoryStatusId || "");
  const [selectedBrand, setSelectedBrand] = useState(itemData?.brandId || "");
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [selectedResponsible, setSelectedResponsible] = useState(null);
  const [selectedDebit, setSelectedDebit] = useState(null);
  const [userData, setUserData] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [inventoryPictureTBL, setInventoryPictureTBL] = useState<any[]>([]);

  const [imageUrl, setImageUrl] = useState<string>("");

  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    fetchCompanies();
    if (inventoryId !== "0") {
      fetchItemData(inventoryId);
    }
  }, [inventoryId]);


  useEffect(() => {
    if (!userData || !itemData?.inventoryResponsibleTBL) return;

    const responsibleList = itemData.inventoryResponsibleTBL.map(item => item.responsibleUserId);

    const filteredData = userData.filter(user => responsibleList.includes(user.userId));

    setFilteredUserData(filteredData);
  }, [userData, itemData]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCampusByCompany(selectedCompany);
    } else {
      setCampusData([]);
    }
  }, [selectedCompany]);

  const fetchCompanies = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const fetchAllLocationName = async (companyId: number) => {
    try {
      const result = await HTTPService.GETAllLocationNames(companyId);
      setLocationData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };


  const fetchAllCategoryName = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCategoryByCompany(companyId);
      setCategoryData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const fetchAllType = async (companyId: number) => {
    try {
      const result = await HTTPService.GETTypeNames(companyId);
      setTypeData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const fetchAllTypeByCategory = async (categoryId: string) => {
    try {
      const result = await HTTPService.GETTypeNamesByCategory(categoryId.toString());
      setTypeData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };


  const fetchAllDepartment = async (companyId: number) => {
    try {
      const result = await HTTPService.GETDepartmentNames(companyId);
      setDepartmentData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const fetchAllSupplier = async (companyId: number) => {
    try {
      const result = await HTTPService.GETSupplierNames(companyId);
      setSupplierData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const fetchAllBrand = async (companyId: number) => {
    try {
      const result = await HTTPService.GETBrandNames(companyId);
      setBrandData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };


  const fetchAllStatus = async (companyId: number) => {
    try {
      const result = await HTTPService.GETInventoryStatusNames(companyId);
      setInventoryStatusData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };


  const fetchItemData = (inventoryId: string) => {
    HTTPService.GET(`/Inventory/ById?inventoryId=${inventoryId}`).then(async (res) => {
      setInventoryPictureTBL(res.data.inventoryPictureTBL.length)
      if (res.status === 200) {
        setItemData(res.data);
        setImageUrl(res.data.imageUrl);
        fetchAllLocationName(res.data.companyId)
        fetchCampusByCompany(res.data.companyId)
        fetchAllType(res.data.companyId)
        fetchAllTypeByCategory(res.data.itemTypeTBL.categoryId)
        fetchAllDepartment(res.data.companyId)
        fetchAllSupplier(res.data.companyId)
        fetchAllBrand(res.data.companyId)
        fetchAllStatus(res.data.companyId)
        fetchUser(res.data.companyId)
        fetchAllCategoryName(res.data.companyId)
        setSelectedCompany(res.data.companyId);
        setSelectedLocation(res.data.locationId);
        setSelectedCategory(res.data.itemTypeTBL.categoryId);
        setSelectedDepartment(res.data.departmentId);
        setSelectedBrand(res.data.brandId);
        setSelectedType(res.data.typeId);
        setSelectedSupplier(res.data.supplierId);
        setSelectedDebit(res.data.debtUserId);
        setSelectedStatus(res.data.inventoryStatusId);
        setSelectedCampus(res.data.locationTBL.campusId);
      } else {
        toast.error(res.data);
      }
    }).catch(err => {
      console.error(err);
      toast.error(t('error_message'));
    });
  };

  const DeletePicture = (pictureId: number) => {
    HTTPService.DELETE(`/Inventory/deletePicture?pictureId=${pictureId}`).then(async (res) => {
      if (res.status === 200) {
        toast.success(t(res.data))
        setItemData(prevItemData => ({
          ...prevItemData,
          inventoryPictureTBL: prevItemData.inventoryPictureTBL.filter(picture => picture.pictureId !== pictureId)
        }));
      }
      else if (res.status === 450) {
        toast.error(t(res.data));
      }
      else {
        toast.error(t(res.data));
      }
    }).catch(err => {
      console.error(err);
      toast.error(t('error_message'));
    });
  };

  // const handleImageChange = (info) => {
  //   if (info.file === 'done') {
  //     message.success(`${info.file.name} ${t('file_uploaded')}`);
  //     setImageUrl(info.file.response.url); 
  //   } else if (info.file.status === 'error') {
  //     message.error(`${info.file.name} ${t('file_upload_error')}`);
  //   }
  // };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   //fetchItemData()
  //   const fileList = event.target.files;
  //   if (fileList) {
  //     const files = Array.from(fileList) as File[];

  //     // 100KB sınırı
  //     const maxSizeInBytes = 100 * 1024;

  //     // Geçerli ve geçersiz dosyaları filtrele
  //     const validFiles = files.filter(file => file.size <= maxSizeInBytes);
  //     const invalidFiles = files.filter(file => file.size > maxSizeInBytes);

  //     if (invalidFiles.length > 0) {
  //       notification.warning({
  //         message: t('fileSizeLimit'),
  //         description: t('fileSizeLimitDescription'),
  //       });
  //     }


  //     // Toplam resim sayısını güncelle
  //     setSelectedImages(prevImages => {
  //       const currentTotalImages = itemData.inventoryPictureTBL.length + prevImages.length;
  //       const totalFiles = currentTotalImages + validFiles.length;

  //       if (totalFiles > 3) {
  //         // Toplamda 3'ü geçmemek için izin verilen dosyaları kısıtla
  //         const allowedFiles = validFiles.slice(0, 3 - (prevImages.length + inventoryPictureTBL.length));
  //         notification.warning({
  //           message: t('limit_exceeded'),
  //           description: t('threeImage'),
  //         });

  //         // Seçilen resimleri güncelle
  //         return [
  //           ...prevImages,
  //           ...allowedFiles
  //         ];
  //       } else {
  //         // Toplamda 3'ü aşmayan dosyaları ekle
  //         return [
  //           ...prevImages,
  //           ...validFiles
  //         ];
  //       }
  //     });

  //     // Dosya input'ını temizle
  //     event.target.value = '';
  //   }
  // };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //fetchItemData()
    const fileList = event.target.files;
    if (fileList) {
      const files = Array.from(fileList) as File[];
  
      // Geçerli ve geçersiz dosyaları filtrele
      const validFiles = files; // Remove file size restriction
      const invalidFiles: File[] = []; // No invalid files since we removed the size check
  
      if (invalidFiles.length > 0) {
        notification.warning({
          message: t('fileSizeLimit'),
          description: t('fileSizeLimitDescription'),
        });
      }
  
      // Toplam resim sayısını güncelle
      setSelectedImages(prevImages => {
        const currentTotalImages = itemData.inventoryPictureTBL.length + prevImages.length;
        const totalFiles = currentTotalImages + validFiles.length;
  
        if (totalFiles > 3) {
          // Toplamda 3'ü geçmemek için izin verilen dosyaları kısıtla
          const allowedFiles = validFiles.slice(0, 3 - (prevImages.length + inventoryPictureTBL.length));
          notification.warning({
            message: t('limit_exceeded'),
            description: t('threeImage'),
          });
  
          // Seçilen resimleri güncelle
          return [
            ...prevImages,
            ...allowedFiles
          ];
        } else {
          // Toplamda 3'ü aşmayan dosyaları ekle
          return [
            ...prevImages,
            ...validFiles
          ];
        }
      });
  
      // Dosya input'ını temizle
      event.target.value = '';
    }
  };
  

  const showModalCampus = () => {
    setIsModalVisibleCampus(true);
  };

  const showModalStatus = () => {
    setIsModalVisibleInventoryStatus(true);
  };

  const showModalBrand = () => {
    setIsModalVisibleBrand(true);
  };

  const showModalDepartment = (id: number) => {
    setCompanyId(id);
    setIsModalVisibleDepartment(true);
  };

  const showModalSupplier = () => {
    setIsModalVisibleSupplier(true);
  };


  const showModalType = () => {
    setIsModalVisibleType(true);
  };


  const showModalLocation = () => {
    setIsModalVisibleLocation(true);
  };

  const handleCancelCampus = () => {
    setIsModalVisibleCampus(false);
    fetchCampusByCompany(selectedCompany || user.companyId)
  };

  const handleCancelBrand = () => {
    setIsModalVisibleBrand(false);
    fetchAllBrand(itemData.companyId)
  };

  const handleCancelStatus = () => {
    setIsModalVisibleInventoryStatus(false);
    fetchAllStatus(itemData.companyId)
  };


  const handleCancelDepartment = () => {
    setIsModalVisibleDepartment(false);
    fetchAllDepartment(itemData.companyId);
  };

  const handleCancelSupplier = () => {
    setIsModalVisibleSupplier(false);
    fetchAllSupplier(itemData.companyId)
  };


  const handleCancelCategory = () => {
    setIsModalVisibleCategory(false);
    fetchAllCategoryName(itemData.companyId)
  };

  const handleCancelType = () => {
    setIsModalVisibleType(false);
    fetchAllType(selectedCompany)
    fetchAllTypeByCategory(selectedCategory.toString())
  };

  const fetchAllLocationNameByCampus = async (campusId: string) => {
    try {
      const result = await HTTPService.GETAllLocationNameByCampus(campusId);
      setLocationData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const uploadProps: UploadProps = {
    action: 'Inventory/UploadImage/',
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
    },
    beforeUpload: (file: RcFile) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('Only image files are allowed.');
      }
      return isImage;
    },
    onRemove: () => {
      setImageUrl('');
    },
  };

  const columns: IAltisDataGridColumns[] = [
    {
      title: t('Responsible Name'),
      key: 'displayName',
      placeHolder: t('Responsible Name'),
      inputType: "input",
    },
    {
      title: t('Operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: "operations",

      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={50}>
            <Popconfirm
              title={<div>
                {t('responsibleToast')} <br />
              </div>}
              onConfirm={() => DeleteResponsible(record.userId)}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
              >{t('Delete')}</Button>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const DeleteResponsible = (id: number) => {

    setFilteredUserData(prevData => prevData.filter(item => item.userId != id));
    const responsible = userData.find(responsible => responsible.userId === id);
    if (responsible) {
      toast.success(`"${responsible.displayName}"` + " " + t('responsibleDeleteToast'));
    } else {
      toast.error(t('responsibleNotFound'));
    }
  };

  useEffect(() => {
  }, [filteredUserData]);

  const handleAddResponsible = () => {
    const selectedUserData = userData.find(user => user.userId === selectedResponsible);

    if (selectedUserData && !filteredUserData.some(user => user.userId === selectedUserData.userId)) {
      setFilteredUserData(prevData => [
        ...prevData,
        { userId: selectedUserData.userId, displayName: selectedUserData.displayName }
      ]);
    }
  };

  const showModalCategory = () => {
    setIsModalVisibleCategory(true);
  };

  const fetchCampusByCompany = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCampusByCompany(companyId);
      setCampusData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const handleCancelLocation = () => {
    setIsModalVisibleLocation(false);
    fetchAllLocationName(selectedCompany);
    fetchAllLocationNameByCampus(selectedCampus.toString())
  };

  const savedNewCampus = (campus: ICampusData) => {
    setSelectedCampus(campus.campusId)
  }

  const savedNewLocation = (location: ILocationData) => {
    setSelectedLocation(location.locationId)
  }

  const savedNewCategory = (category: ICategoryData) => {
    setSelectedCategory(category.categoryId)
  }

  const savedNewType = (type: ITypeData) => {
    setSelectedType(type.typeId)
  }

  const savedNewDepartment = (department: IDepartmentData) => {
    setSelectedDepartment(department.departmentId)
  }

  const savedNewSupplier = (supplier: ISupplierData) => {
    setSelectedSupplier(supplier.supplierId)
  }

  const savedNewBrand = (brand: IBrandData) => {
    setSelectedBrand(brand.brandId)
  }


  const savedNewStatus = (status: IInventoryStatusData) => {
    setSelectedStatus(status.inventoryStatusId)
  }


  const handleClickDeparment: React.MouseEventHandler<HTMLElement> = (event) => {
    showModalDepartment(itemData?.companyId)
  };

  const updatedItem = (formData) => {
    const updatedItem = {
      inventoryId: inventoryId,
      inventoryName: formData.inventoryName,
      serialNo: formData.serialNo,
      barcode: formData.barcode,
      referanceNo: formData.referanceNo,
      locationId: selectedLocation,
      departmentId: selectedDepartment,
      supplierId: selectedSupplier,
      brandId: selectedBrand,
      categoryId: selectedCategory,
      inventoryStatusId: selectedStatus,
      typeId: selectedType,
      debtUserId: selectedDebit,
      inventoryResponsibleTBL: filteredUserData.map(user => ({
        responsibleUserId: user.userId,
        inventoryId: inventoryId,
      }))
    };


    HTTPService.PUT(`/Inventory/updateInventory`, updatedItem)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.replace('/admin/inventory-management');
        } else {
          toast.error(t('update_failed'));
        }
      })
      .catch((error) => {
        console.error(t('update_failed'));
      });
  };


  if (selectedImages.length > 0 && selectedImages.length <= 3) {
    const formData = new FormData();



    // const updatedPicture = {
    //   inventoryId:inventoryId,
    //   file:selectedImages
    // };

    selectedImages.forEach((image, index) => {
      formData.append('file', image);
      formData.append('inventoryId', inventoryId);
    });

    HTTPService.PUT(`/Inventory/updateInventoryPicture`, formData)
      .then((response) => {
        if (response.status === 200) {
          var tmp = { ...itemData };
          tmp.inventoryPictureTBL = tmp.inventoryPictureTBL.concat(response.data);
          setSelectedImages([])
          setItemData(tmp)
          setSelectedImages([])
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          //setSelectedImages(response.data)
        } else {
          toast.error(t('update_failed'));
        }
      })
      .catch((error) => {
        console.error(t('update_failed'));
      });
  }

  //const handleImageRemove = (index: number) => {
  //setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
  //DeletePicture(itemData.inventoryPictureTBL.find(picture=>picture.pictureId))
  //};


  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  const handleSelectChange = (value) => {
    setSelectedResponsible(value);

    message.error({
      content: t('pressPlusIcon'),
      background: 'red'
    });
  };

  const handleGridChange = () => {
    fetchUser(selectedCompany);
  };


  const fetchUser = async (companyId: number) => {
    try {
      const result = await HTTPService.GETUserNames(companyId);
      setUserData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  return (
    <>
      <h3>{t('update_item')}</h3>
      <Form
        layout='vertical'
      // onFinish={updatedPicture}
      >
        <Card style={{ padding: '50px' }}>
          <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {itemData && itemData.inventoryPictureTBL && itemData.inventoryPictureTBL.length > 0 && (
                itemData.inventoryPictureTBL.map((picture, index) => (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      width: '150px',
                      height: '150px',
                    }}
                  >
                    <Image
                      src={`${API_URL}/images/${picture.pictureGuid}`}
                      alt={`Resim ${index}`}
                      width={150}
                      height={150}
                      preview={false}
                      style={{ borderRadius: '4px' }}
                    />
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => DeletePicture(picture.pictureId)}
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        zIndex: 1,
                      }}
                      type="primary"
                      danger
                    />
                  </div>
                ))
              )}

            </div>
          </div>
          {/* Form item for uploading new images */}
          <Form.Item
            name='images'
            label={t('upload_image')}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
              multiple
            />
            <Button
              icon={<UploadOutlined />}
              //onClick={() => fileInputRef.current?.click()}
              onClick={() => fileInputRef.current.click()}
              style={{ marginTop: '20px' }}
            >
              {t('Select Images')}
            </Button>
          </Form.Item>
        </Card>
      </Form>
      <br></br>

      <Card style={{ padding: '50px' }}>
        <Form
          layout='vertical'
          onFinish={updatedItem}
          fields={[
            { name: ["inventoryName"], value: itemData?.inventoryName },
            { name: ["serialNo"], value: itemData?.serialNo },
            { name: ["barcode"], value: itemData?.barcode },
            { name: ["campusId"], value: itemData?.locationTBL?.campusTBL?.campusId },
            { name: ["locationId"], value: itemData?.locationId },
            { name: ["categoryId"], value: itemData.itemTypeTBL?.categoryId },
            { name: ["typeId"], value: itemData?.typeId },
            { name: ["departmentId"], value: itemData?.departmentId },
            { name: ["supplierId"], value: itemData?.supplierId },
            { name: ["brandId"], value: itemData?.brandId },
            { name: ["inventoryStatusId"], value: itemData?.inventoryStatusId },
            { name: ["referanceNo"], value: itemData?.referanceNo },
            { name: ["debtUserId"], value: itemData?.debtUserId },
          ]}
        >

          {/* Campus Select */}
          <Form.Item
            //name='campusId'
            label={t('campus')}
            rules={[{ message: t('select_campus') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('campus')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedCampus}
              onChange={(value) => (setSelectedCampus(value), setSelectedLocation(""), fetchAllLocationNameByCampus(value.toString()))}
              filterOption={filterOption}
            >
              {campusData.map(row => (
                <Select.Option key={row.campusId} value={row.campusId}>
                  {row.campusName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Campus Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalCampus}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>

          {/* Location Select */}
          <Form.Item
            //name='locationId'
            label={t('location')}
            rules={[{ required: true, message: t('select_location') }]}
            style={{ marginBottom: 0 }}
          >
            <Select
              placeholder={t('location')}
              //onChange={handleLocationChange}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedLocation}
              onChange={(value) => setSelectedLocation(value)}
              filterOption={filterOption}
            >
              {locationData.map(row => (
                <Select.Option key={row.locationId} value={row.locationId}>
                  {row.locationName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Location Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalLocation}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>

          </Form.Item>
          <Modal
            visible={isModalVisibleLocation}
            footer={null}
            onCancel={handleCancelLocation}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddLocation savedObj={savedNewLocation} cancelModal={handleCancelLocation} companyId={itemData.companyId} campusId={itemData?.locationTBL?.campusTBL?.campusId} locationId={itemData.locationId} locationName={itemData.locationName} />
          </Modal>

          <Modal
            visible={isModalVisibleCampus}
            footer={null}
            onCancel={handleCancelCampus}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddCampus cancelModal={handleCancelCampus} savedObj={savedNewCampus} companyId={itemData.companyId} campusName={itemData.locationTBL?.campusTBL?.campusName} />
          </Modal>

          {/* Category Select */}
          <Form.Item
            //name='categoryId'
            label={t('item_group')}
            rules={[{ required: true, message: t('category') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('item_group')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              filterOption={filterOption}
              value={selectedCategory}
              //   onChange={(value) => {
              //     setSelectedCategory(value);
              //     fetchAllTypeByCategory(value);
              //     setSelectedType("");
              // }}
              onChange={(value) => (setSelectedCategory(value), setSelectedType(''), fetchAllTypeByCategory(value.toString()))}
            >


              {categoryData.map(row => (
                <Select.Option key={row.categoryId} value={row.categoryId}>
                  {row.categoryName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Category Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalCategory}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>

          <Modal
            visible={isModalVisibleCategory}
            footer={null}
            onCancel={handleCancelCategory}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddCategory cancelModal={handleCancelCategory} savedObj={savedNewCategory} companyId={itemData.companyId} categoryId={itemData?.itemTypeTBL?.categoryId} categoryName={itemData?.itemTypeTBL?.categoryTBL?.categoryName} />
          </Modal>

          {/* Category Select */}
          <Form.Item
            //name='typeId'
            label={t('item_type')}
            rules={[{ required: true, message: t('type') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('item_type')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
              filterOption={filterOption}
            >
              {typeData.map(row => (
                <Select.Option key={row.typeId} value={row.typeId}>
                  {row.typeName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalType}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>

          <Modal
            visible={isModalVisibleType}
            footer={null}
            onCancel={handleCancelType}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddType cancelModal={handleCancelType} savedObj={savedNewType} companyId={itemData.companyId} categoryId={itemData?.itemTypeTBL?.categoryTBL?.categoryId} typeName={itemData?.itemTypeTBL?.typeName} typeId={itemData?.itemTypeTBL?.typeId} />
          </Modal>

          <Form.Item
            //name='departmentId'
            label={t('Department')}
            rules={[{ required: true, message: t('Department') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('Department')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedDepartment}
              filterOption={filterOption}
              onChange={(value) => setSelectedDepartment(value)
              }>

              {departmentData.map(row => (
                <Select.Option key={row.departmentId} value={row.departmentId}>
                  {row.departmentName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Category Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleClickDeparment}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>

          <Modal
            visible={isModalVisibleDepartment}
            footer={null}
            onCancel={handleCancelDepartment}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddDepartment cancelModal={handleCancelDepartment} savedObj={savedNewDepartment} companyId={itemData.companyId} departmentId={itemData.departmentId} departmentName={itemData.departmentName} />
          </Modal>

          <Form.Item
            //name='supplierId'
            label={t('supplier')}
            rules={[{ required: true, message: t('supplier') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('supplier')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedSupplier}
              filterOption={filterOption}
              onChange={(value) => setSelectedSupplier(value)

              }
            >
              {supplierData.map(row => (
                <Select.Option key={row.supplierId} value={row.supplierId}>
                  {row.supplierName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Category Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalSupplier}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>
          <Modal
            visible={isModalVisibleSupplier}
            footer={null}
            onCancel={handleCancelSupplier}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddSupplier cancelModal={handleCancelSupplier} savedObj={savedNewSupplier} companyId={itemData.companyId} supplierName={itemData.supplierName} supplierId={itemData.supplierId} />
          </Modal>

          <Form.Item
            //name='brandId'
            label={t('brand')}
            rules={[{ required: true, message: t('brand') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('brand')}
              style={{ width: 'calc(100% - 50px)' }}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedBrand}
              filterOption={filterOption}
              onChange={(value) => setSelectedBrand(value)
              }
            >
              {brandData.map(row => (
                <Select.Option key={row.brandId} value={row.brandId}>
                  {row.brandName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalBrand}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>
          <Modal
            visible={isModalVisibleBrand}
            footer={null}
            onCancel={handleCancelBrand}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddBrand cancelModal={handleCancelBrand} savedObj={savedNewBrand} companyId={itemData.companyId} brandName={itemData.brandName} brandId={itemData.brandId} />
          </Modal>

          <Form.Item
            //name='inventoryStatusId'
            label={t('inventory_status_name')}
            rules={[{ required: true, message: t('inventory_status_name') }]}
            style={{ marginTop: -10, marginBottom: 0 }}
          >
            <Select
              placeholder={t('inventory_status_name')}
              style={{ width: 'calc(100% - 50px)' }}
              filterOption={filterOption}
              dropdownStyle={{ minWidth: 300 }}
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
            >
              {inventoryStatusData.map(row => (
                <Select.Option key={row.inventoryStatusId} value={row.inventoryStatusId}>
                  {row.inventoryStatusName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Category Button */}
          <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }}
          >
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModalStatus}
                  style={{ marginLeft: 8, marginTop: -55 }}  // Responsive margin
                />
              </Col>
            </Row>
          </Form.Item>
          <Modal
            visible={isModalVisibleInventoryStatus}
            footer={null}
            onCancel={handleCancelStatus}
            width={800}
            bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '25vh' }}
          >
            <AddInventoryStatus savedObj={savedNewStatus} cancelModal={handleCancelStatus} companyId={itemData.companyId} inventoryStatusName={itemData.inventoryStatusName} inventoryStatusId={itemData.inventoryStatusId}
            />
          </Modal>

          <Form.Item
            //name='debtUserId'
            label={t('Debit Name')}
            rules={[{ required: false, message: t('debit_name') }]}
          >
            <Select
              placeholder={t('Debit Name')}
              filterOption={filterOption}
              onChange={(value) => setSelectedDebit(value)}
              value={selectedDebit}
            >
              <Select.Option key="none" value={null}>
                {" "}
              </Select.Option>
              {userData.map(row => (
                <Select.Option key={row.userId} value={row.userId}>
                  {row.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='responsibleList'
            label={t('responsibleName')}
            rules={[{ required: false, message: t('selectResponsible') }]}
            style={{ marginBottom: 0 }}
          >
            <Row gutter={8} align="middle">
              <Col flex="auto">
                <Select
                  showSearch
                  placeholder={t('selectResponsible')}
                  onChange={handleSelectChange}
                  filterOption={filterOption}
                >
                  {userData?.map((responsible) => (
                    <Select.Option key={responsible.userId} value={responsible.userId}>
                      {responsible.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <Button type="primary" onClick={handleAddResponsible}>
                  {t('addResponsible')}
                </Button>
              </Col>
            </Row>
            <br></br>
            <AltisDataGrid
              data={filteredUserData}
              total={filteredUserData.length}
              columns={columns}
              onChange={handleGridChange}
            />
            <br></br>
          </Form.Item>

          <Form.Item
            name='inventoryName'
            label={t('inventoryName')}
            rules={[{ required: true, message: t('input_item_name'), whitespace: true }]}
          >
            <Input
              placeholder={t('item-name')}
              value={itemData.inventoryName}
              onChange={(e) => setItemData(prevData => ({ ...prevData, inventoryName: e.target.value }))}
            />
          </Form.Item>

          <Form.Item
            name='serialNo'
            label={t('serial_no')}
            rules={[{ required: false, message: t('input_serial_no'), whitespace: true }]}
          >
            <Input
              placeholder={t('serial_no')}
              value={itemData.serialNo}
              onChange={(e) => setItemData(prevData => ({ ...prevData, serialNo: e.target.value }))}
            />
          </Form.Item>

          <Form.Item
            name='barcode'
            label={t('barcode')}
          >
            <Input
              placeholder={t('barcode')}
              value={itemData.barcode}
              readOnly
            />
          </Form.Item>

          <Form.Item
            name='referanceNo'
            label={t('Referance No')}
          >
            <Input
              placeholder={t('Referance No')}
              value={itemData.referanceNo}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={[8, 8]} justify="end" align="middle">
              <Col xs={12} sm={12} md={4}>
                <Button block href="/admin/inventory-management" type="primary" style={{ borderRadius: 0 }}>
                  {t('cancel')}
                </Button>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                  {t('update')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default UpdateInventory;