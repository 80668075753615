import { DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Image, Modal, notification, Pagination, Popconfirm, Row, Select, SelectProps, Space, Spin, Tooltip } from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useRef, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { IInventory } from '../../../interfaces/IItemData';
import { IPrinterData } from '../../../interfaces/IPrinterData';
import { ICompanyData } from '../../../interfaces/ICompanyData';
import { ITemplatesName } from '../../../interfaces/ITemplateName';
import form from 'antd/lib/form';
import i18n from '../../../i18n';
import { IInventoryStatusData } from '../../../interfaces/IInventoryStatusData';
import JSZip from 'jszip';
import { API_URL } from '../../../utils/Constants';

const InventoryManagement: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [inventoryData, setInventoryData] = useState<IInventory>();
  const [templateData, setTemplateData] = useState<ITemplatesName[]>();
  const [printerData, setPrinterData] = useState<IPrinterData[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleExport, setIsModalVisibleExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);
  const [isModalImage, setIsModalImage] = useState(false);
  const [isModalMovement, setIsModalMovement] = useState(false);
  const imagesPerPage = 1
  const [currentPage, setCurrentPage] = useState(1);
  const [movementData, setMovementData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [inventoryId, setInventoryId] = useState(null);


  const dataGridRef = useRef<any>(null);
  // useEffect(() => {
  //     InventoryFilter();
  //     const intervalId = setInterval(() => {
  //         if (dataGridRef.current) {
  //             dataGridRef.current.clearData();
  //         }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  // }, [])



  const pageData: IPageData = {
    title: t('item-management'),
    loaded: true,
  };



  useEffect(() => {
    onSetPage(pageData);
    InventoryFilter();
  }, [i18n.language]);

  const paginatedImages = () => {
    if (!Array.isArray(imageSrc)) {
      return []; // Return an empty array if imageSrc is not an array
    }
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = Math.min(startIndex + imagesPerPage, imageSrc.length);
    return imageSrc.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil((imageSrc?.length || 0) / imagesPerPage);

  const generatePagination = () => {
    const pages = [];
    const showPages = 3; // Her iki tarafta gösterilecek sayfa sayısı

    if (totalPages <= showPages * 2 + 1) {
      // Toplam sayfa sayısı azsa tüm sayfaları göster
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // İlk 3 sayfa ve son 3 sayfa gösterilecek
      for (let i = 1; i <= showPages; i++) {
        pages.push(i);
      }
      if (currentPage > showPages + 2) {
        pages.push('...');
      }
      const startPage = Math.max(1, totalPages - showPages + 1);
      for (let i = startPage; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const ImagePreview = async (id) => {
    HTTPService.GET(`/Inventory/pictureByInventory?inventoryId=${id}`).then(res => {
      if (res.status == 200) {

        setImageSrc(res.data);
        setIsModalImage(true);
      }
      else {
        console.error("asdas");
      }
    })
      .catch((error) => {
        console.error('Error fetching images:', error);
      })
  };


  //   const MovementPreview = async (id) => {
  //     if (typeof id !== 'number') {
  //         console.error('Invalid ID:', id);
  //         return;
  //     }
  //     try {
  //         const response = await HTTPService.POST(`/InventoryTracking/inventoryTracking?`);
  //         console.log('API Response:', response.data);
  //         if (!Array.isArray(response.data)) {
  //             console.error('Expected an array but received:', response.data);
  //             return;
  //         }
  //         setMovementData(response.data); 
  //         setIsModalMovement(true); 
  //     } catch (error) {
  //         toast.error(t('errorFetchingData'));
  //     }
  // };

  const MovementPreview = (e?) => {
    var inventoryIdTmp = e?.inventoryId;

    if (inventoryIdTmp === null || inventoryIdTmp === undefined) {
      inventoryIdTmp = inventoryId
    } else {
      setInventoryId(inventoryIdTmp)
    }

    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      inventoryId: inventoryIdTmp,
      updatedDate: e?.updatedDate,
      inventoryTracking: e?.inventoryTracking,
      oldRecord: e?.oldRecord,
      newRecord: e?.newRecord
    }


    HTTPService.POST(`/InventoryTracking/InventoryTracking`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setMovementData(response.data);
        setIsModalMovement(true);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DetailPreview = async (id) => {
    if (typeof id !== 'number') {
      console.error('Invalid ID:', id);
      return;
    }
    try {
      const response = await HTTPService.GET(`/Inventory/inventoryDetails?id=${id}`);
      setDetailData(response.data);
      setIsModalDetail(true);
    } catch (error) {
      toast.error(t('errorFetchingData'));
    }
  };


  const PDFPriview = async (values) => {
    setIsLoading(true)
    try {
      const selectedPrintsDetails = inventoryData.data.filter(item => item.isSelected);
      const printInventoryBarcode = selectedPrintsDetails.map(item => item.barcode);

      const body = {
        templateId: values.selectTemplate,
        barcode: printInventoryBarcode
      };

      await HTTPService.POSTblob(`/Inventory/pdfpreview`, body);
    } catch (error) {
      console.error('Error in PDF preview:', error);
    }
    finally {
      setIsLoading(false);
      setIsModalVisibleExport(false);
    }
  };


  const AllTemplateName = (values) => {
    let companyIdToUse = values;
    HTTPService.GET(`/Template/allTemplateName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ITemplatesName[] = response.data;
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        setTemplateData(tmp);
      })
      .catch((error) => {
        console.error('Şablon çekme hatası:', error);
      });
  };


  const AllPrinterName = (values) => {
    let companyIdToUse = values;
    HTTPService.GET(`/Printer/allPrinterName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IPrinterData[] = response.data;
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        setPrinterData(tmp);
      })
      .catch((error) => {
        console.error('Cihaz çekme hatası:', error);
      });
  };


  const handleSelectAll = (e) => {
    var details = { ...inventoryData };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
    } else {
      details.data.map((row) => (row.isSelected = false));
    }

    setInventoryData(details);
  };

  const handleRowSelect = (e, index) => {
    var newObject = { ...inventoryData };
    newObject.data[index].isSelected = e.target.checked;
    setInventoryData(newObject);
  };


  const dropdownItemStatus: IDropdownItem[] = inventoryData?.statusData
    ? inventoryData.statusData.map((elem) => ({
      id: elem.inventoryStatusName,
      name: elem.inventoryStatusName,
    }))
    : [];

  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            inventoryData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>

      ),
    },
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'input'
    }] : []),
    { title: t('inventoryName'),   isSorting:true, key: 'inventoryName', placeHolder: t('inventoryName'), inputType: 'input' },
    { title: t('barcode'),    isSorting:true, key: 'barcode', placeHolder: t('barcode'), inputType: 'input' },
    { title: t('item_type'),    isSorting:true, key: 'typeName', placeHolder: t('item_type'), inputType: 'input', width: 500 },
    { title: t('item_group'),   isSorting:true, key: 'categoryName', placeHolder: t('item_group'), inputType: 'input', width: 500 },
    { title: t('location'),   isSorting:true, key: 'locationName', placeHolder: t('location'), inputType: 'input' },
    { title: t('inventory_status_name')   ,isSorting:true, key: 'inventoryStatusName', placeHolder: t('inventory_status_name'), inputType: 'dropdown', dropdownItem: dropdownItemStatus, },
    { title: t('isPrinted'),    isSorting:true,key: 'isPrinted', placeHolder: t('isPrinted'), inputType: 'checkbox' },
    ...(userInfo.role === UserRole.Personel || userInfo.role === UserRole.Operator ? [{
      title: t('debbit'),    isSorting:true,key: 'debbit', placeHolder: t('debbit'), inputType: 'checkbox'
    }] : []),
    ...(userInfo.role === UserRole.Personel || userInfo.role === UserRole.Operator ? [{
      title: t('responsible'),   isSorting:true, key: 'responsible', placeHolder: t('responsible'), inputType: 'checkbox'
    }] : []),
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: 'operations',
      isSorting:true,
      width: 900,
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space>
            <Popconfirm
              title={t('are_you_sure_inventory')}
              onConfirm={() => DeleteInventory(record.inventoryId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')} placement="top">
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  style={{ display: userInfo.role === UserRole.Personel ? 'none' : 'inline-block', width: '60px', borderRadius: '70px' }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
            </Popconfirm>
            <Tooltip title={t('update')} placement="top">
              <Button
                size='small'
                icon={<EditOutlined />}
                style={{ display: userInfo.role === UserRole.Personel ? 'none' : 'inline-block', width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  const inventoryId = record.inventoryId;
                  window.location.replace(`/admin/update-inventory/${inventoryId}`);
                }}
              />
            </Tooltip>
            <Tooltip title={t('preview')} placement="top">
              <Button
                size='small'
                icon={<EyeOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  ImagePreview(record.inventoryId);
                }}
              />
            </Tooltip>
            <Tooltip title={t('details')} placement="top">
              <Button
                size='small'
                icon={<UnorderedListOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  DetailPreview(record.inventoryId);
                }}
              />
            </Tooltip>
            <Tooltip title={t('movements')} placement="top">
              <Button
                size='small'
                icon={<HistoryOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  MovementPreview({ inventoryId: record.inventoryId })
                }}
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('Debit') },
    { id: 1, name: t('Responsible') },
    { id: 2, name: t('Campus') },
    { id: 3, name: t('Location') },
    { id: 4, name: t('InventoryStatus') },
    { id: 5, name: t('Department') }
  ];


  const columns1: IAltisDataGridColumns[] = [
    {
      title: t('date'),
      key: 'updatedDate',
      placeHolder: t('date'),
      inputType: 'date',
      render: (text: string) => {
        if (!text) return '';


        const [date, time] = text.split('T');
        const parts = date.split('-');
        const day = parts[2];
        const month = parts[1];
        const year = parts[0];
        const [hours, minutes] = time.split(':');

        return `${day}.${month}.${year} ${hours}:${minutes}`;;
      }
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('movementType')}</div>,
      key: t('inventoryTracking'),
      placeHolder: t('movementType'),
      inputType: 'multiplecheckbox',
      dropdownItem: dropdownItemType,
      width: 500,
      render: (value) => {
        return <div style={{ textAlign: 'center' }}>{t(value)}</div>;
      }
    },
    {
      title: t('oldRecord'),
      key: 'oldRecord',
      placeHolder: t('oldRecord'),
      inputType: 'input'
    },
    {
      title: t('newRecord'),
      key: 'newRecord',
      placeHolder: t('newRecord'),
      inputType: 'input'
    },
  ];

  const columns2: IAltisDataGridColumns[] = [
    {
      title: t('responsible'),
      key: 'inventoryResponsibleTBL',
      placeHolder: t('responsible'),
      inputType: 'operations',
      render: (record) => {

        const responsibleInfo = record[0];

        if (responsibleInfo && responsibleInfo.responsibleUserTBL) {
          const user = responsibleInfo.responsibleUserTBL;
          return `${user.firstName} ${user.lastName}`;
        }
        return t('noResponsible');
      }
    },
    {
      title: t('debit'),
      key: 'debtUser',
      placeHolder: t('debit'),
      inputType: 'operations',
      render: (record) => {

        const debitUser = record;

        if (debitUser) {
          return `${debitUser.firstName} ${debitUser.lastName}`;
        }
        return t('noDebit');
      }
    },
    {
      title: t('serial_no'),
      key: 'serialNo',
      placeHolder: t('serial_no'),
      inputType: 'input'
    },
    {
      title: t('Referance No'),
      key: 'referanceNo',
      placeHolder: t('Referance No'),
      inputType: 'input'
    },
    //  {
    //    title: t('debit'), 
    //    key: 'debtUserId', 
    //    placeHolder: t('debit'),
    //    inputType: 'operations',
    //    render: (record) => {
    //      console.log(record); 

    //      const responsibleInfo = record[1];     
    //      console.log(responsibleInfo);

    //      if (responsibleInfo && responsibleInfo.debtUserId) { 
    //          const user = responsibleInfo.debtUserId; 
    //          console.log(user)
    //          return `${user.firstName} ${user.lastName}`; 
    //      }
    //      return 'No responsible user found'; 
    //    }
    //  },
  ];


  const InventoryFilter = (e?) => {
    
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      barcode: e?.barcode,
      departmentName: e?.departmentName,
      inventoryName: e?.inventoryName,
      inventoryStatusName: e?.inventoryStatusName,
      typeName: e?.typeName,
      debtFirstName: e?.debtFirstName,
      debtLastName: e?.debtLastName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      itemName: e?.itemName,
      itemGroup: e?.itemGroup,
      locationName: e?.locationName,
      categoryName: e?.categoryName,
      debbit: e?.debbit,
      responsible: e?.responsible,
      isPrinted: e?.isPrinted
    };
    HTTPService.POST(`/Inventory/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setInventoryData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DeleteInventory = (id) => {
    HTTPService.DELETE(`/Inventory/deleteInventory?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
        InventoryFilter();
      } else {
        toast.error(t(response.data));
      }
    });
  };



  const PrintData = (values) => {
    setIsLoading(true); // Start loading

    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);
    const printInventoryBarcode = selectedInventory.map((item) => item.barcode);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    var body = {
      printerId: values.selectPrinter,
      companyId: distinctCompanyIds[0],
      templateId: values.selectTemplate,
      printsBarcode: printInventoryBarcode
    };


    HTTPService.POST(`/Inventory/PrintInventory`, body)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
        } else {
          toast.error(t('printError')); // api timeouta düşüyor geçici olarak response.data kaldırılmıştır.
        }
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
        setIsModalVisible(false); // Hide modal
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleCancelExport = () => {
    setIsModalVisibleExport(false);
  }

  const handleCancelImage = () => {
    setIsModalImage(false);
  }

  const handleCancelMovement = () => {
    setIsModalMovement(false);
  }

  const handleCancelDetail = () => {
    setIsModalDetail(false);
  }

  const showModal = () => {
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);

    // const companyIds = selectedInventory.((item) => item.companyId);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if (distinctCompanyIds.length > 1) {
      setIsModalVisible(false);
      toast.error(t('sameCompany'))
    } else if (selectedInventory.length === 0) {
      setIsModalVisible(false);
      toast.error(t('anyInventory'))
    }
    else {
      setIsModalVisible(true);
    }
    AllTemplateName(distinctCompanyIds[0]);
    AllPrinterName(distinctCompanyIds[0]);
  };

  const showModalExport = () => {
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);

    // const companyIds = selectedInventory.((item) => item.companyId);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if (distinctCompanyIds.length > 1) {
      setIsModalVisibleExport(false);
      toast.error(t('sameCompany'))
    } else if (selectedInventory.length === 0) {
      setIsModalVisibleExport(false);
      toast.error(t('anyInventory'))
    }
    else {
      setIsModalVisibleExport(true);
    }
    AllTemplateName(distinctCompanyIds[0]);
  };


  return (
    <>

      <Row gutter={16} align="middle" style={{ marginBottom: '20px' }}>

        <Col
          xs={24}
          sm={userInfo?.role === UserRole.AltisAdmin ? 8 : 12}
          md={userInfo?.role === UserRole.AltisAdmin ? 8 : 12}
          lg={userInfo?.role === UserRole.AltisAdmin ? 6 : 8}
          xl={userInfo?.role === UserRole.AltisAdmin ? 6 : 8}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap' }}>
            <Button
              type='primary'
              shape='round'
              size='middle'
              onClick={showModal}
              hidden={userInfo.role === UserRole.Personel}
              style={{
                flex: '1 1 auto',
                minWidth: '120px',
                marginRight: '8px',
              }}
            >
              {t('print')}
            </Button>

            <Button
              type='primary'
              shape='round'
              size='middle'
              onClick={showModalExport}
              hidden={userInfo.role === UserRole.Personel}
              style={{
                flex: '1 1 auto',
                minWidth: '120px',
                marginRight: '8px',
              }}
            >
              {t('PDF Export')}
            </Button>

            <Button
              type='primary'
              shape='round'
              style={{
                height: 40,
                borderRadius: '30px',
                marginRight: '8px',
                flex: '1 1 auto',
                minWidth: '120px',
              }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-inventory')}
              hidden={userInfo.role === UserRole.Personel}
            >
              {t('add_new_item')}
            </Button>

            <Button
              type='primary'
              shape='round'
              style={{
                height: 40,
                borderRadius: '30px',
                marginRight: '8px',
                flex: '1 1 auto',
                minWidth: '120px',
              }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-inventory-quick')}
              hidden={userInfo.role === UserRole.Personel}
            >
              {t('add_new_item_speed')}
            </Button>
          </div>

        </Col>
      </Row>

      <Modal
        title={t('Template Printing')}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <div style={{ padding: '20px' }}>
          <Spin spinning={isLoading}>
            <Form onFinish={PrintData}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_template')}
                    name='selectTemplate'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_template'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }} // Adjusted margin
                  >
                    <Select
                      key='selectTemplateUpload'
                      style={{ width: '100%' }} // Ensure full width
                    >
                      {templateData?.map((item) => (
                        <Select.Option key={item.templateId} value={item.templateId}>
                          {item.templateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_printer')}
                    name='selectPrinter'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_printer'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }} // Adjusted margin
                  >
                    <Select
                      key='selectPrinterUpload'
                      className='custom-select'
                      style={{ width: '100%' }} // Ensure full width
                    >
                      {printerData?.map((item) => (
                        <Select.Option key={item.printerId} value={item.printerId}>
                          {item.printerName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='primary'
                    htmlType="submit"
                    shape='round'
                    size='middle'
                    style={{ width: 'auto' }}
                  >
                    {t('print')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>


      <Modal
        title={t('PDF Export')}
        visible={isModalVisibleExport}
        onCancel={handleCancelExport}
        width={800}
        footer={null}
      >
        <div style={{ padding: '20px' }}>
          <Spin spinning={isLoading}>
            <Form onFinish={PDFPriview}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_template')}
                    name='selectTemplate'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_template'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }}
                  >
                    <Select
                      key='selectTemplateUpload'
                      style={{ width: '100%' }}
                    >
                      {templateData?.map((item) => (
                        <Select.Option key={item.templateId} value={item.templateId}>
                          {item.templateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='primary'
                    htmlType="submit"
                    shape='round'
                    size='middle'
                    style={{ width: 'auto' }}
                  >
                    {t('PDF Export')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>


      {/* <Modal
      title={<span style={{ fontWeight: 'bold' }}>{t('image_preview')}</span>}
    visible={isModalImage}
    onCancel={handleCancelImage}
    footer={null}
>
    {imageSrc.length > 0 ? (
        <>
            <div style={{ textAlign: 'center' }}>
                {paginatedImages().map((image, index) => (
                    <Image
                        key={index}
                        src={image.src}
                        alt={image.name}
                        style={{ width: 'auto', height: 'auto', maxWidth: '100%' }}
                    />
                ))}
            </div>
            {totalPages > 1 && (
                <Pagination
                    current={currentPage}
                    pageSize={imagesPerPage}
                    total={imageSrc.length}
                    onChange={page => setCurrentPage(page)}
                    style={{ textAlign: 'center', marginTop: '16px' }}
                    itemRender={(page, type, originalElement) => {
                        if (type === 'page') {
                            const pages = generatePagination();
                            if (pages.includes(page) || page.toString() === '...') {
                                return <span style={{ padding: '0 8px', cursor: 'pointer', fontWeight: currentPage === page ? 'bold' : 'normal' }}>{page}</span>;
                            }
                            return originalElement;
                        }
                        return originalElement;
                    }}
                />
            )}
        </>
    ) : (
        <p style={{ textAlign: 'center' }}>{t('noImage')}</p>
    )}
</Modal> */}


      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('image_preview')}</span>}
        visible={isModalImage}
        onCancel={handleCancelImage}
        footer={null}
      >
        {imageSrc.length > 0 ? (
          <><div style={{ textAlign: 'center' }}>
            {paginatedImages().map((image, index) => (
              <Image
                key={image.pictureGuid}
                src={`${API_URL}/images/${image.pictureGuid}`}
                alt={`Resim ${index}`}
                width={350}
                height={350}
                preview={{
                  src: `${API_URL}/images/${image.pictureGuid}`,
                }}
                style={{ borderRadius: '4px' }}
              />
            ))}
          </div>

            {totalPages > 1 && (
              <Pagination
                current={currentPage}
                pageSize={imagesPerPage}
                total={imageSrc.length}
                onChange={page => setCurrentPage(page)}
                style={{ textAlign: 'center', marginTop: '16px' }}
                itemRender={(page, type, originalElement) => {
                  if (type === 'page') {
                    const pages = generatePagination();
                    if (pages.includes(page) || page.toString() === '...') {
                      return <span style={{ padding: '0 8px', cursor: 'pointer', fontWeight: currentPage === page ? 'bold' : 'normal' }}>{page}</span>;
                    }
                    return originalElement;
                  }
                  return originalElement;
                }}
              />
            )}
          </>
        ) : (
          <p style={{ textAlign: 'center' }}>{t('noImage')}</p>
        )}
      </Modal>

      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('details')}</span>}
        visible={isModalDetail}
        onCancel={handleCancelDetail}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          {detailData ? (
            <>
              <AltisDataGrid
                data={detailData}
                total={detailData?.count}
                columns={columns2}
                onChange={DetailPreview}
              />
            </>
          ) : (
            <p>{t('loading')}</p>
          )}
        </div>
      </Modal>

      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('movementTrace')}</span>}
        visible={isModalMovement}
        onCancel={handleCancelMovement}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          {movementData ? (
            <>
              <AltisDataGrid
                data={movementData?.data}
                total={movementData?.count}
                columns={columns1}
                onChange={(e) => {
                  MovementPreview({
                    ...e,
                    inventoryId: inventoryId,
                  });
                }}
              />
            </>
          ) : (
            <p>{t('loading')}</p>
          )}
        </div>
      </Modal>


      <Row>
        <Col span={24}>
          <AltisDataGrid
            ref={dataGridRef}
            data={inventoryData?.data}
            total={inventoryData?.count}
            columns={columns}
            onChange={InventoryFilter}
          />
        </Col>
      </Row>
    </>
  );



};

export default InventoryManagement;
