import React, {CSSProperties} from 'react';

import './Logo.scss';
import altisLogo from './../../../assets/img/altisLogo.png'
import altisLogoLight from './../../../assets/img/altisLogo-light.png'
import rubberLogo from './../../../assets/img/sumitomorubberako-logo.png'
import flag from './../../../assets/img/turkey-flag.png'
import altisLogoBeyaz from './../../../assets/img/altisLogo_beyaz.png'
import altisbeyaz from './../../../assets/img/altisbeyaz.png'
import TagandTrace_logo from './../../../assets/img/TagandTrace_logo.png'
import TagAndTrace_beyaz from './../../../assets/img/TagAndTrace_beyaz.png'


interface LogoProps {
  alt?: string;
  light?: boolean;
  width?: number | string;
  height?: number | string;
  source?: string;
  style?: CSSProperties;
}

const Logo: React.FunctionComponent<LogoProps> = props => {
  const { source, style } = props;


  return (
          <div style={style}>
            <div className='logo-wrap'>
              <img 
                src={source === "TagAndTrace_beyaz" ?TagAndTrace_beyaz :(source === "altisbeyaz" ?altisbeyaz :(source === "altisLogo"?altisLogo :(source==="altisLogoLight" ? altisLogoLight : (source === "TagandTrace_logo" ? TagandTrace_logo : TagandTrace_logo )) )) } 
                alt={props.alt} 
                width={props.width} 
                height={props.height}/>
            </div>
          </div>
  )
};

Logo.defaultProps = {
  width: 'auto',
  height: 'auto',
  light: false,
  alt: ''
};

export default Logo;
