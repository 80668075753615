import React from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AddCustomer: React.FunctionComponent<IPageProps> = () => {
  const { Option } = Select;
  const { t } = useTranslation();

  const cities = [
    'Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Amasya', 'Ankara', 'Antalya',
    'Artvin', 'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu',
    'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır',
    'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun',
    'Gümüşhane', 'Hakkâri', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
    'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya',
    'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş',
    'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop',
    'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
    'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale',
    'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük',
    'Kilis', 'Osmaniye', 'Düzce'
  ];

  const CompanyRegister = (values) => {
    const newGroup = {
      companyName: values.companyName,
      companyAddress: values.companyAddress, 
      companyCity:values.companyCity,
      responsibleUserTBL: {
        userEmail: values.responsibleEmail,
        userPassword: values.responsibleUserPassword, 
        firstName: values.responsibleUserFirstname,
        lastName: values.responsibleUserLastname,
        telephone:values.responsibleTelephone,
      }
    };


    

    HTTPService.POST(`/Company/registerCompany`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-customer';
          if (currentUrl.includes(redirectUrl)) {
              window.location.replace('admin/customer-management');
          }
        }else if(response.status ===450){
          toast.error(t(response.data))
        }
         else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        toast.error(t('register_failure'));
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/customer-management/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('register_customer')}</h3>
      <Form onFinish={CompanyRegister} layout='vertical'>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='companyName'
              label={t('company_name')}
              rules={[{ required: true, message: t('please_input', { field: t('company_name') }), whitespace: true }]}
            >
              <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder={t('company_name')} />
            </Form.Item>
          </Col>
         
        </Row>
        <Form.Item
          name='companyAddress'
          label={t('address')}
          rules={[{ required: true, message: t('please_input', { field: t('address') }), whitespace: true }]}
        >
          <Row gutter={16}>
            <Col span={16}>
              <Input
                prefix={<HomeOutlined />}
                style={{ borderRadius: 0 }}
                placeholder={t('address')}
              />
            </Col>
            <Col span={8}>
              <Form.Item
                name='companyCity'
                rules={[{ required: true, message: t('please_select', { field: t('city') }) }]}
              >
                <Select
                  showSearch
                  placeholder={t('select_city')}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
        label={t('responsible_firstname')}
        name='responsibleUserFirstname'
        rules={[
          { 
            required: true, 
            message: t('please_input', { field: t('responsible_firstname') }), 
            whitespace: true 
          },
          {
            pattern: /^(?!\s)(?!.*\s$).+$/,
            message: t('no_leading_or_trailing_spaces'),
          }
        ]}
      >
        <Input 
          prefix={<UserOutlined />} 
          style={{ borderRadius: 0 }} 
          placeholder={t('responsible_firstname')} 
        />
      </Form.Item>
        <Form.Item
          label={t('responsible_lastname')}
          name='responsibleUserLastname'
          rules={[{ required: true, message: t('please_input', { field: t('responsible_lastname') }), whitespace: true },
            {
              pattern: /^(?!\s)(?!.*\s$).+$/,
              message: t('no_leading_or_trailing_spaces'),
            }
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder={t('responsible_lastname')} />
        </Form.Item>

        <Form.Item
          name='responsibleTelephone'
          label={t('responsible_telephone')}
          rules={[
            { required: true, message: t('please_input', { field: t('responsible_telephone') }) },
            { pattern: /^[0-9]*$/, message: t('only_numbers') },
          ]}
        >
          <Input
            prefix={<PhoneOutlined />}
            style={{ borderRadius: 0 }}
            placeholder='+90 512 6897 2541'
          />
        </Form.Item>

        <Form.Item
          name='responsibleEmail'
          label={t('responsible_email')}
          rules={[
            { required: true, message: t('please_input', { field: t('responsible_email') }), whitespace: true },
            { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('valid_email') },
          ]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} placeholder='example@gmail.com' />
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddCustomer;
