import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next'; // import useTranslation hook
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { IDepartmentData } from "../../../interfaces/IDepartmentData";

const AddDepartment: React.FC<IDepartmentData> = ({ savedObj, cancelModal, companyId, departmentName }) => {
  const { t } = useTranslation();
  const [departmentData, setDepartmentData] = useState<IDepartmentData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };


  const DepartmentRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newGroup = {
      departmentName: values.departmentName || departmentName,
      companyId: companyIdToUse || companyId,
    };

    HTTPService.POST(`/Department/registerDepartment`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          console.log("Departman başarıyla kaydedildi.");
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-department';
          if (currentUrl.includes(redirectUrl)) {
            window.location.replace('admin/department-management');
          }
          else {
            savedObj(response.data)
            cancelModal();
          }
        } else if (response.status === 450) {
          toast.error(t(response.data));
        }
        else {
          console.error("Departman kaydı başarısız.");
          toast.error(t(response.data));
        }

      })
      .catch((error) => {
        console.error("Departman kaydı başarısız: ", error);
      });
  };

  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';

    if (currentUrl.includes(addInventoryUrl)) {
      cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
      cancelModal();
    } else {
      window.location.replace("/admin/department-management/");
    }
  };

  return (
    <Card style={{ padding: "50px" }}>
      <h3>{t('register_department')}</h3>
      <Form onFinish={DepartmentRegister} layout="vertical"
        fields={[
          { name: ["selectedCompany"], value: companyId },
        ]}
      >
        {!isCompanyIdAvailable && userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="departmentName"
              label={t('department_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_department_name'),
                  whitespace: true,
                }
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                style={{ borderRadius: 0, width: '100%' }}
                placeholder={t('department_name')}
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddDepartment;
