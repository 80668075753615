import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tr from './translations/tr/translation.json'; // Türkçe dil dosyası
import en from './translations/en/translation.json'; // İngilizce dil dosyası

i18n
  .use(initReactI18next) // react-i18next'i kullan
  .init({
    resources: {
      tr: {
        translation: tr
      },
      en: {
        translation: en
      }
    },
    lng: 'tr', // Varsayılan dil
    fallbackLng: 'en', // Dil bulunamazsa varsayılan dil
    interpolation: {
      escapeValue: false // react zaten bu işlemi yapar
    }
  });

export default i18n;
