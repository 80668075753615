import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { IBrandData } from "../../../interfaces/IBrandData";
import { useTranslation } from 'react-i18next';
import { IPageProps } from "../../../interfaces/page-data";


const UpdateBrand: React.FunctionComponent<IPageProps> = () => {
  const { brandId } = useParams<any>();
  const [brandData, setBrandData] = useState<IBrandData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
    if (brandId) {
      getBrandData(brandId);
    }
  }, [brandId]);

  useEffect(() => {
    if (brandId != null) {
      getBrandData(brandId);
    } else if (brandData?.brandId) {
      getBrandData(brandData.brandId);
    }
  }, [brandId, brandData?.brandId]);

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };


  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  const getBrandData = (brandId: number) => {
    HTTPService.GET(`/Brand/ById?brandId=${brandId}`)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setBrandData(res.data);
        } else {
          toast.error(res.error?.message || t('error_fetching_data'));
        }
      })
      .catch((error) => {
        toast.error(t('error_fetching_data'));
        console.error("Error fetching brand data:", error);
      });
  };

  const updateBrand = (formData) => {
    const updatedBrand = {
      brandId: brandId,
      brandName: formData.brandName,
      companyId: formData.companyId || userInfo.companyId,
    };

    HTTPService.PUT(`/Brand/updateBrand`, updatedBrand)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('brand_updated_successfully'));
          window.location.replace("/admin/brand-management");
        } else if (res.status === 450) {
          toast.error(t(res.data))
        }
        else {
          toast.error(t('brand_update_failed'));
        }


      })
      .catch(() => {
        toast.error(t('brand_update_failed'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/brand-management/");
  };

  return (
    <Form
      layout="vertical"
      onFinish={updateBrand}
      fields={[
        { name: ["brandName"], value: brandData?.brandName },
        { name: ["brandId"], value: brandId || brandData?.brandId },
        { name: ["companyId"], value: brandData?.companyId },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_brand')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="brandName"
              label={t('brand_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_brand_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('brand_name')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateBrand;
