import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import { IInventoryStatusData } from "../../../interfaces/IInventoryStatusData";
import i18n from "../../../i18n";

export interface IInventoryStatus {
  count?: number;
  data?: IInventoryStatusData[];
}

const InventoryStatusManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const [inventoryStatusData, setInventoryStatusData] = useState<IInventoryStatus>({});
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('inventory_status_management'), 
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    InventoryStatusFilter();
  }, [i18n.language]);

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: 'Tcp Client' },
    { id: 1, name: 'Tcp Server' },
    { id: 2, name: 'Web Socket Client' },
    { id: 3, name: 'Web Service' },
    { id: 4, name: 'Serial Port' },
    { id: 5, name: 'Web Socket Server' }
  ];

  const columns: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'input'
    }] : []),
    { title: t('inventory_status_name'),isSorting:true, key: 'inventoryStatusName', placeHolder: t('inventory_status_name'), inputType: "input" },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure')} <br /></div>}
              onConfirm={() => DeleteInventoryStatus(record.inventoryStatusId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
            <Tooltip title={t('delete')} placement="top">
             <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'}}
              >
              </Button> 
            </Tooltip>
         
            </Popconfirm>
            <Tooltip title={t('update')} placement="top">
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => window.location.replace('/admin/update-inventory-status/' + record.inventoryStatusId)}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'}}
              >
              </Button>
            </Tooltip>
         
          </Space>
        </div>
      ),
    }
  ];

  const InventoryStatusFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';
    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      inventoryStatusName: e?.inventoryStatusName,
      companyName: e?.companyName,
      sortColumn,
      sortOrder
    };

    HTTPService.POST(`/InventoryStatus/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setInventoryStatusData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };



  const DeleteInventoryStatus = (Id) => {
    HTTPService.DELETE(`/InventoryStatus/deleteInventoryStatus?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-inventory-status')}
          >
            {t('add_new_inventory_status')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className='table-container'>
            <AltisDataGrid data={inventoryStatusData.data} total={inventoryStatusData.count} columns={columns} onChange={InventoryStatusFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryStatusManagement;
