import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { useTranslation } from 'react-i18next'; 
import { IPageProps } from "../../../interfaces/page-data";

const UpdateCategory: React.FunctionComponent<IPageProps> = () => {
  const { categoryId } = useParams<any>();
  const [categoryData, setCategoryData] = useState<ICategoryData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (categoryId != null) {
      getCategoryData(categoryId);
    } else if (categoryData?.categoryId) {
      getCategoryData(categoryData.categoryId);
    }
  }, [categoryId, categoryData?.categoryId]);

  const fetchData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  const getCategoryData = (categoryId: number) => {
    HTTPService.GET(`/Category/ById?categoryId=${categoryId}`)
      .then((res) => {
        if (res.status === 200) {
          setCategoryData(res.data);
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  };

  const updateCategory = (formData) => {
    const updatedCategory = {
      categoryId: categoryId,
      categoryName: formData.categoryName,
      companyId: formData.companyId || userInfo?.companyId,
    };

    HTTPService.PUT(`/Category/updateCategory`, updatedCategory)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('category_updated_successfully'));
            window.location.replace("/admin/category-management");
        }else if(res.status===450){
          toast.error(t(res.data))
        } 
        else {
          toast.error(t(res.data));
        }
      })
      .catch((error) => {
        toast.error(t('error_updating_category'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/category-management/");
  };

  const { Option } = Select;

  return (
    <Form
      layout="vertical"
      onFinish={updateCategory}
      fields={[
        { name: ["categoryName"], value:categoryData?.categoryName },
        { name: ["companyId"], value:categoryData?.companyId },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_category')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ width: '100%' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="categoryName"
              label={t('category_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_category_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ width: '100%',borderRadius: '0' }} placeholder={t('category_name')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={24} sm={12} md={6}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateCategory;
