import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next'; // import useTranslation hook
import { ISupplierData } from "../../../interfaces/ISupplierData";


const AddSupplier: React.FC<ISupplierData> = ({cancelModal,savedObj, companyId, supplierId, supplierName }) => {
  const { t } = useTranslation(); 
  const [supplierData, setSupplierData] = useState<ISupplierData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  const SupplierRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newGroup = {
      supplierName: values.supplierName || supplierName,
      companyId: companyIdToUse || companyId,
    };

    HTTPService.POST(`/Supplier/registerSupplier`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          console.log("Tedarikçi başarıyla kaydedildi.");
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-supplier';
          if (currentUrl.includes(redirectUrl)) {
              window.location.replace('admin/supplier-management');
          }else if(response.status===450){
            toast.error(t(response.data));
          }
          else{
            savedObj(response.data);
            cancelModal();
          }
        } else {
          console.error("Tedarikçi kaydı başarısız.");
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        console.error("Tedarikçi kaydı başarısız: ", error);
      });
  };

  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';

    if (currentUrl.includes(addInventoryUrl)) {
        cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
        cancelModal();
    } else {
        window.location.replace("/admin/supplier-management/");
    }
};

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  return (
    <Card style={{ padding: "50px" }}>
      <h3>{t('register_supplier')}</h3>
      <Form onFinish={SupplierRegister} layout="vertical" 
        fields={[
          { name: ["selectedCompany"], value: companyId },
        ]}
      >
        {!isCompanyIdAvailable&&userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
      <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          name="supplierName"
          label={t('supplier_name')}
          rules={[
            {
              required: true,
              message: t('please_input_your_supplier_name'),
              whitespace: true,
            }
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            style={{ borderRadius: 0, width: '100%' }} 
            placeholder={t('supplier_name')}
            maxLength={50}
          />
        </Form.Item>
      </Col>
    </Row>
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddSupplier;
