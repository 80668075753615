import React, { useEffect, useState } from 'react';
import axios from 'axios';

import '../BaseLayout/BaseLayout.scss';
import './Admin.scss';

import { connect } from 'react-redux';
import { Route } from 'react-router';

import { toggleSidebar } from '../../redux/settings/actions';

import BaseLayout from '../BaseLayout/BaseLayout';
import Navbar from '../components/Navbar/Navbar';
import Menu from '../components/Menu/Menu';
import Logo from '../components/Logo/Logo';
import Footer from '../components/Footer/Footer';
import Actions from '../components/Actions/Actions';
import NavbarSkeleton from '../components/NavbarSkeleton/NavbarSkeleton';

import { IPageData } from '../../interfaces/page-data';
import { IMenuItem } from '../../interfaces/menu';
import { IAppSettings } from '../../interfaces/settings';

import { AppState } from '../../redux/store';

import BasePage from '../../pages/BasePage/BasePage';

import { defaultRoutes } from '../../routes';

import { resetPageData, setPageData } from '../../redux/pages/actions';
import {Toaster} from "react-hot-toast";
import { StateLoader } from '../../redux/StateLoader';
import { Button } from 'antd';
import { IAuthentication } from '../../interfaces/authentication';
import SignalRHub from '../../utils/SignalRHub';

const getGradientString = (firstColor: string, secondColor: string): string =>
  `linear-gradient(188deg, ${firstColor}, ${secondColor} 65%)`;

interface AdminLayoutProps {
  pageData: IPageData;
  settings: IAppSettings;
  onSidebarToggle: () => void;
  onSettingsReset: () => void;
  onSettingsSet: (data: IAppSettings) => void;
  onSettingsUpdate: (data: IAppSettings) => void;
  onSetPage: (data: IPageData) => void;
  onPageReset: () => void;
}

const AdminLayout: React.FunctionComponent<AdminLayoutProps> = props => {
  const { pageData, settings, onSidebarToggle, onPageReset, onSetPage } = props;
  const {
    sidebarAccentColor,
    sidebarColor,
    topbarColor,
    topbarBg,
    //sidebarBg2,
    //sidebarBg,
    sidebarAccentContrastColor,
    sidebarOpened,
    //layout,
    boxed
  } = settings;
  const [menuData, setMenuData] = useState<IMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // const [settingsVisibility, setSettingsVisibility] = useState(false);
  const routes = defaultRoutes;
  const signalRHub = new SignalRHub(); 


  const loader =  new StateLoader();
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const userInfo: IAuthentication = loader.loadAuthState();
      
      try {
        const result = await axios({ baseURL: '', url: './data/menu.json', method: 'get' });
        const data: IMenuItem[] = result.data; 
        setMenuData(
          data.filter(item => 
            {
              if(item.permission == null)
                {
                return item;
              }
                var check = item.permission.some(role => userInfo.role === role);
                return check;
            }
        ));

      //   setMenuData(data.filter(item => {
      //     return true;
      //     console.log(item)
      //     if (item.groupTitle) {
      //         if (Array.isArray(item.role)) {
      //             //return item.role.some(role => userInfo.role[role]);
      //         } else {
      //             //return userInfo.role === item.role;
      //         }
      //     } else {
      //         return userInfo.role[item.role];
      //     }
      // })
      //);
      } catch (err) {
        console.error('Server Error', err);
        setMenuData([]); 
      } finally {
        setIsLoading(false); // Yükleme durumunu kapat
      }
    }
  
    fetchData();
  }, []);

  

  const handleToggleSidebar = () => {
    onSidebarToggle();
  };

  // const handleSettingsModalClose = () => {
  //   setSettingsVisibility(false);
  // };

  // const handleSettingsClick = () => setSettingsVisibility(true);

  return (
    <div className='layout vertical'>
      <div className={`app-container ${boxed && 'boxed'}`}>
        <Navbar
          style={{ backgroundImage: getGradientString("#00d4ff", "#090979")}}
          orientation='vertical'
          opened={sidebarOpened}
          onCloseNavbar={handleToggleSidebar}>
          <Button
            className='no-style navbar-close icofont-close-line d-lg-none'
            onClick={onSidebarToggle}
          />
          <Logo style={{ backgroundColor: topbarBg,marginLeft:1.2}} source='TagandTrace_logo'/>
          <Menu 
            color={sidebarColor}
            accentContrast={sidebarAccentContrastColor}
            accentColor={sidebarAccentColor}
            data={menuData}
            layout={'admin'}
          />
          <Logo  style={{ padding: '30px' }}   source='altisbeyaz'/>
          <NavbarSkeleton type='vertical' loaded={true} />
        </Navbar>

        <Navbar
          boxed={boxed}
          style={{ backgroundColor: topbarBg, color: topbarColor}}
          orientation='horizontal'
          className='horizontal-nav'
          minHeight={60}>
          <button className='navbar-toggle d-lg-none' onClick={handleToggleSidebar}>
            <span />
            <span />
            <span />
          </button>

          <Actions signalRHub={signalRHub} />

          <NavbarSkeleton type='horizontal' loaded={true} />
        </Navbar>

        <Toaster
            position="top-right"
            toastOptions={{
              style: {
                width: '280px',
                height: '70px',
                fontSize: '15px',
                color: 'white'
              },
              success: {
                duration: 3000,
                iconTheme: {
                  primary: '#65CC6D',
                  secondary: 'white'
                },
                style: {
                  background: '#65CC6D',
                }
              },
              error: {
                iconTheme: {
                  primary: '#FF6961',
                  secondary: 'white'
                },
                duration: 3000,
                style: {
                  background: '#FF6961',
                },
              },
              custom: {
                iconTheme: {
                  primary: '#FFA500',
                  secondary: 'white'
                },
                duration: 4000,
                style: {
                  background: '#FFA500',
                },
              }
            }}
        />

        <BaseLayout
          pageData={pageData}
          settings={settings}
          onPageReset={onPageReset}
          onSidebarToggle={onSidebarToggle}>
          {routes.map((route, i) => {
            return (
              <Route
                exact
                key={i}
                path={`/admin${route.path}`}
                render={() => (
                  <BasePage>
                    <route.component signalRHub={signalRHub} onSetPage={onSetPage} />
                  </BasePage>
                )}
              />
            );
          })}
        </BaseLayout>

        {/* <Footer boxed={boxed} loaded={true} /> */}

        {/* <button className='no-style settings-btn' onClick={handleSettingsClick}>
          <span className='pulse' />
          <span className='icofont-bucket2' />
        </button> */}
      </div>

      {/* <Settings opened={settingsVisibility} onClose={handleSettingsModalClose} /> */}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pageData: state.pageData,
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarToggle: () => dispatch(toggleSidebar()),
  onSetPage: (data: IPageData) => dispatch(setPageData(data)),
  onPageReset: () => dispatch(resetPageData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLayout);
