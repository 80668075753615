import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

export interface IApplication {
  count?: number;
  data?: IApplicationData[];
}

const ResponsibleManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const [applicationData, setCategoryData] = useState<IApplication>({});
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('responsible_management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    ResponsibleFilter();
  }, [i18n.language]);

  

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('tcp_client') },
    { id: 1, name: t('tcp_server') },
    { id: 2, name: t('web_socket_client') },
    { id: 3, name: t('web_service') },
    { id: 4, name: t('serial_port') },
    { id: 5, name: t('web_socket_server') }
  ];

  const columns: IAltisDataGridColumns[] = [
    { title: t('company_name'), key: 'companyName', placeHolder: t('company_name'), inputType: "input" },
    { title: t('firstName'), key: 'firstName', placeHolder: t('firstName'), inputType: "input" },
    { title: t('lastName'), key: 'lastName', placeHolder: t('lastName'), inputType: "input" },
  ];

  const ResponsibleFilter = (e?) => {
    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      firstName: e?.firstName,
      lastName: e?.lastName,
      companyName: e?.companyName,
    };

    HTTPService.POST(`/User/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setCategoryData(response.data);
      } else {
        toast.error(response.data);
      }
      const tmp: IApplication = response.data;
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setCategoryData(tmp);
    });
  };

  const DeleteResponsible = (Id) => {
    HTTPService.DELETE(`/User/deleteResponsible?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
      </div>
      <div className="row">
        <div className="col-md-13">
          <AltisDataGrid data={applicationData.data} total={applicationData.count} columns={columns} onChange={ResponsibleFilter} />
        </div>
      </div>
    </>
  );
};

export default ResponsibleManagement;
