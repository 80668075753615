import * as UserTypes from './types';

import {IAuthentication} from "../../interfaces/authentication";

export const setUser = (data: IAuthentication): UserTypes.SetUserAction => ({
    type: UserTypes.SET_USER,
    payload: data
});

export const updateUser = (data: IAuthentication): UserTypes.UpdateUserAction => ({
    type: UserTypes.UPDATE_USER,
    payload: data
});

export const removeUser = (): UserTypes.RemoveUserAction => ({
    type: UserTypes.REMOVE_USER
});
