import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { IInventoryStatusData } from "../../../interfaces/IInventoryStatusData";

const AddInventoryStatus: React.FC<IInventoryStatusData> = ({savedObj,companyId,inventoryStatusName,cancelModal})  => {
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState<ICategoryData[]>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  
  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const InventoryStatusRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newGroup = {
      inventoryStatusName: values.inventoryStatusName||inventoryStatusName,
      companyId: companyIdToUse || companyId,
    };

    HTTPService.POST(`/InventoryStatus/registerInventoryStatus`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-inventory-status';
         if (currentUrl.includes(redirectUrl)) {
            window.location.replace('admin/inventory-status-management');
          }
          else if(response.status===450){
            toast.error(t(response.data));
          }
          else
          {
            savedObj(response.data)
            cancelModal();
          }
        } else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        toast.error("Kategori kaydı başarısız: " + error.message);
      });
  };



  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';

    if (currentUrl.includes(addInventoryUrl)) {
        cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
        cancelModal();
    } else {
        window.location.replace("/admin/inventory-status-management/");
    }
};

  return (
    <Card
      style={{ padding: "50px"}} 
    >
      <h3>{t('register_inventory_status')}</h3>
      <Form onFinish={InventoryStatusRegister} layout="vertical" 
        fields={[
          { name: ["selectedCompany"], value: companyId },
        ]}
      >
        {!isCompanyIdAvailable && userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="inventoryStatusName"
              label={t('inventory_status_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_inventory_status_name'),
                  whitespace: true,
                }
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                className="input-custom"
                placeholder={t('inventory_status_name')}
                style={{ borderRadius: 0 }} 
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddInventoryStatus;
