import { useTranslation } from 'react-i18next';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import { Button, Checkbox, Col, Form, Image, Modal, Popconfirm, Row, Select, Table } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { IPrintDetails, IPrintDetailsData } from '../../../interfaces/IPrintDetailsData';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { IPrinterData } from '../../../interfaces/IPrinterData';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';

const PrintsJobsDetailsExcel: React.FunctionComponent<IPageProps> = (props) => {
  const { t } = useTranslation(); // Add this line for translation
  const { printsJobsId } = useParams<any>();
  const { onSetPage } = props;
  const [printDetails, setPrintDetails] = useState<IPrintDetails>();
  const [isModalVisible, setIsModalVisible] = useState<number>(null);
  const [selectPrinter, setSelectPrinter] = useState<IPrinterData[]>();
  const [isModalImage, setIsModalImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<number | null>(null);
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  const handleCancel = () => {
    setIsModalVisible(null);
  };

  const pageData: IPageData = {
    title: t('prints_jobs_details_excel'), // Use translation here
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TagFilter();
    AllPrinterName();
  }, [printsJobsId]);

  const AllPrinterName = () => {
    HTTPService.GET(`/Device/AllPrinterNames`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IPrinterData[] = response.data;
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        setSelectPrinter(tmp);
      })
      .catch((error) => {
        console.error(t('error_fetching_printer_names'), error); // Use translation
      });
  };

  const TagFilter = (e?: any) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      status: e?.status ?? null,
      printsJobsId: Number(printsJobsId),
      filterCreatedStartTime:
        e?.filterCreatedDate_start === null || e?.filterCreatedDate_start === ''
          ? null
          : new Date(Date.parse(e?.filterCreatedDate_start)),
      filterCreatedFinishTime:
        e?.filterCreatedDate_finish === null || e?.filterCreatedDate_finish === ''
          ? null
          : new Date(Date.parse(e?.filterCreatedDate_finish)),
      filterPrintedStartTime:
        e?.filterPrintedDate_start === null || e?.filterPrintedDate_start === ''
          ? null
          : new Date(Date.parse(e?.filterPrintedDate_start)),
      filterPrintedFinishTime:
        e?.filterPrintedDate_finish === null || e?.filterPrintedDate_finish === ''
          ? null
          : new Date(Date.parse(e?.filterPrintedDate_finish)),
    };
    HTTPService.POST(`/PrintsJobsDetails/PrintsJobsDetailsFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        let tmp: IPrintDetails = response.data;
        tmp.data.map((elem, index) => {
          elem['key'] = index;
        });
        setPrintDetails(tmp);
      } else {
        toast.error(response.data);
      }
    }).catch((exception) => {
      console.error(t('error_filtering_prints_jobs_details'), exception); // Use translation
    });
  };

  const DeletePrintsJobsDetails = (id) => {
    HTTPService.DELETE(`/PrintsJobsDetails/DeletePrintJobsDetails?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(response.data);
        window.location.reload();
      } else {
        toast.error(response.data);
      }
    });
  };

  const handleSelectAll = (e) => {
    var details = { ...printDetails };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
    } else {
      details.data.map((row) => (row.isSelected = false));
    }

    setPrintDetails(details);
  };

  const ImagePriview = (id) => {
    HTTPService.POST(`/PrintsJobsDetails/Preview?printsJobsDetailsId=${id}&type=${"image"}`, '')
      .then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          setImageSrc(tmp.value);
          setIsModalImage(true);
        }
      })
      .catch((error) => {
        console.error(t('error_fetching_image'), error); // Use translation
      });
  };

  const PDFPriview = () => {
    const selectedPrintsJobsDetails = printDetails.data.filter((item) => item.isSelected === true);

    const printsJobsDetailsIdList = selectedPrintsJobsDetails.map((item) => item.printsJobsDetailsId);

    HTTPService.POST(`/PrintsJobsDetails/Priview?printsJobsDetailsId=${printsJobsDetailsIdList}&type=${"PDF"}`, '')
      .then((response) => {

      })
      .catch((error) => {
        console.error(t('error_fetching_pdf'), error); // Use translation
      });
  };

  const handleImage = () => {
    setIsModalImage(false);
    setImageSrc('');
  };

  const handleRowSelect = (e, index) => {
    var newObject = { ...printDetails };
    newObject.data[index].isSelected = e.target.checked;
    setPrintDetails(newObject);
  };

  const tagDataColumns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            printDetails?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>
      ),
    },
    {
      title: '',
      key: 'counter',
      placeHolder: t('counter'), // Use translation
      inputType: 'input',
      width: 1000,
    },
    {
      title: t('status'), // Use translation
      key: 'status',
      placeHolder: t('status'), // Use translation
      inputType: 'boolean',
      width: 1000,
      render: (order: number, record: IPrintDetailsData) => (
        <div>
          {record.status !== undefined ? (record.status ? t('printed') : t('not_printed')) : t('unknown')} // Use translation
        </div>
      ),
    },
    {
      title: t('created_date'), // Use translation
      key: 'createdDate',
      placeHolder: t('created_date'), // Use translation
      inputType: 'date',
      width: 1000,
    },
    {
      title: t('printed_date'), // Use translation
      key: 'printedDate',
      placeHolder: t('printed_date'), // Use translation
      inputType: 'date',
      width: 1000,
    },
    {
      title: t('operations'), // Use translation
      key: 'operations',
      placeHolder: t('operations'), // Use translation
      inputType: 'operations',
      width: 1000,
      render: (order, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Button
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              ImagePriview(record.printsJobsDetailsId);
            }}>
            {t('image')} {/* Use translation */}
          </Button>
          {user?.role === UserRole.AltisAdmin ? (
            <Popconfirm
              title={t('confirm_delete')} // Use translation
              onConfirm={() => {
                DeletePrintsJobsDetails(record.printsJobsDetailsId);
              }}
              okText={t('yes')} // Use translation
              cancelText={t('no')} // Use translation
            >
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                {t('delete')} {/* Use translation */}
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              setIsModalVisible(index);
            }}>
            {t('details')} {/* Use translation */}
          </Button>
        </div>
      ),
    },
  ];

  const DetailsColumn = [
    {
      title: t('key'), // Use translation
      key: 'Key',
      dataIndex: 'Key',
    },
    {
      title: t('value'), // Use translation
      key: 'Value',
      dataIndex: 'Value',
    },
  ];

  function GetDetailData() {
    if (printDetails?.data[isModalVisible]?.parameter == null) {
      return [];
    }
    const obj = JSON.parse(printDetails?.data[isModalVisible]?.parameter);

    if (obj != null) {
      let parameters = {};
      try {
        parameters = obj; // JSON stringini parse et
      } catch (e) {
        console.error(t('invalid_json'), obj); // Use translation
        return [];
      }

      const data = Object.keys(parameters)
        .map((key) => {
          let value = parameters[key];
          if (value != null) {
            if (typeof value === 'boolean') {
              value = value ? t('yes') : t('no'); // Use translation
            }
            return {
              Key: key,
              Value: value,
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Null item'ları filtrele

      return data;
    }
    return [];
  }

  const PrintData = (values) => {
    const selectedPrintsJobsDetails = printDetails.data.filter((item) => item.isSelected === true);

    const printsJobsDetailsIdList = selectedPrintsJobsDetails.map((item) => item.printsJobsDetailsId);
    var body = {
      deviceId: selectedDeviceId,
      printsJobsDetailsId: printsJobsDetailsIdList
    };
    HTTPService.POST(`/PrintsJobsDetails/PrintsJobs`, body).then((response) => {
      if (response.status === 200) {
        window.location.reload();
      } else {
        toast.error(response.data);
      }

    }).catch((response) => {
      console.error(response);
    });
  };

  return (
    <>
      <Row gutter={12} align="middle">
        <Col xs={24} sm={12} md={12} lg={8} xl={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginRight: '8px' }}>
            <h6 style={{ display: user.role === UserRole.Sayman ? 'none' : 'block' }}>{t('select_printer')}</h6> {/* Use translation */}
          </div>
          <Form.Item
            name='selectDevice'
            rules={[
              {
                required: true,
                message: t('please_select_printer'), // Use translation
              },
            ]}
            style={{ marginBottom: 0, display: user.role === UserRole.Sayman ? 'none' : 'block' }}
          >
            <Select
              onChange={(value) => setSelectedDeviceId(value)}
              style={{ width: '250px' }}>
              {/* {selectPrinter?.map((item) => (
                <Select.Option key={item.deviceId} value={item.deviceId}>
                  {item.deviceName}
                </Select.Option>
              ))} */}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={7} md={7} lg={4} xl={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            style={{ display: user.role === UserRole.Sayman ? 'none' : 'block' }}
            type='primary'
            shape='round'
            block
            size='middle'
            onClick={PrintData}>
            {t('print')} {/* Use translation */}
          </Button>
        </Col>
        <Col xs={12} sm={7} md={7} lg={4} xl={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button
            style={{ display: 'none' }}
            type='primary'
            shape='round'
            block
            size='middle'>
            {t('pdf_export')} {/* Use translation */}
          </Button>  
        </Col>
      </Row>
      <div style={{ marginBottom: '40px' }}></div>
      <AltisDataGrid
        data={printDetails?.data}
        total={printDetails?.count}
        columns={tagDataColumns}
        onChange={TagFilter}
      />

      <Modal
        title={<span style={{ color: 'red' }}>{t('details')}</span>} // Use translation
        visible={isModalVisible != null}
        bodyStyle={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}
        footer={null}
        onCancel={handleCancel}>
        <Table
          pagination={{
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          columns={DetailsColumn}
          dataSource={GetDetailData()}
        />
      </Modal>

      <Modal title={t('image_preview')} visible={isModalImage} onCancel={handleImage} footer={null}>
        {imageSrc?.labels?.map((labelInfo, index) => (
          <Image
            key={index}
            src={`data:image/png;base64,${labelInfo.imageBase64}`}
            style={{ width: 'auto', height: 'auto' }}
            alt={t('preview')}
          />
        ))}
      </Modal>
    </>
  );
};

export default PrintsJobsDetailsExcel;
