import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ITemplates } from '../../../interfaces/ITemplatesData';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import { useTranslation } from 'react-i18next';

const TemplatesPage: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [templatesData, setTemplatesData] = useState<ITemplates>({});
  const { t } = useTranslation(); // Çeviri fonksiyonunu al

  const userInfo: IAuthentication = (new StateLoader()).loadAuthState();
  const pageData: IPageData = {
    title: t('Templates'),
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TemplatesData();
  }, []);

  const TemplatesData = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';
    const body = {
      sortColumn,
      sortOrder,
      page: e?.page == null ? 1 : e.page,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      templateName: e?.templateName,
      templateCode: e?.templatesCode,
      companyName: e?.companyName,
      companyId: e?.companyId,
      programingLanguage: e?.programingLanguage,
    };
    HTTPService.POST(`/Template/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setTemplatesData(response.data);
      } else {
        toast.error(response.data);
      }
    })
      .catch((exception) => console.error(exception));
  };

  const DeleteTemplates = (id) => {
    HTTPService.DELETE(`/Template/deleteTemplate?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(t(response.data));
      }
    });
  };

  const columnsByUsers: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      isSorting:true,
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    {
      title: t('Templates Name'),
      key: 'templateName',
      isSorting:true,
      placeHolder: t('Templates Name'),
      inputType: 'input',
    },
    {
      title: t('Templates Code'),
      key: 'templatesCode',
      isSorting:true,
      placeHolder: t('Templates Code'),
      inputType: 'input',
    },
    // {
    //   title: t('Programming Language'),
    //   key: 'programingLanguage',
    //   placeHolder: t('Programming Language'),
    //   inputType: 'input',
    // },
    {
      title: t('Operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space>
          <Popconfirm
            title={t('Are you sure you want to delete this template?')}
            onConfirm={() => {
              DeleteTemplates(record.templateId);
            }}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Tooltip title={t('delete')}>
              <Button
                size='small'
                icon={<DeleteOutlined />}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'}}
                onClick={(e) => {
                  e.stopPropagation();
                }}>
              </Button>
            </Tooltip>
       
          </Popconfirm>
          <Tooltip title={t('update')}>
            <Button
              size='small'
              icon={<EditOutlined />}
              style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'}}
              onClick={() => {
                window.location.replace('/admin/update-templates/' + record.templateId);
              }}>
            </Button>
          </Tooltip>
          </Space>
          
          
         
        </div>
      ),
    },
  ];

  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3' style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-templates')}>
            {t('Add New Templates')}
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='table-container'>
            <AltisDataGrid
              data={templatesData.data}
              total={templatesData.count}
              columns={columnsByUsers}
              onChange={TemplatesData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesPage;
