import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import { IPrint, IPrintData } from '../../../interfaces/IPrintData';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  InboxOutlined,
  PrinterOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import FormItem from 'antd/es/form/FormItem';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { ITemplatesName } from '../../../interfaces/ITemplateName';
import { ICompany } from '../../../interfaces/ICompany';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { userInfo } from 'os';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { IPrinterData } from '../../../interfaces/IPrinterData';

const PrintsJobsExcel: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [printList, setPrintList] = useState<IPrint>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleDetails, setIsModalVisibleDetails] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState<ITemplatesName[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [selectPrinter, setSelectPrinter] = useState<IPrinterData[]>([]);
  const [isModalImage, setIsModalImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  const imagesPerPage = 1
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(); // Initialize translation hook
  const [modalData, setModalData] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
    fetchData();
    if (user?.role !== UserRole.AltisAdmin) {
      AllTemplateName(user?.companyId); 
      AllPrinterName(user?.companyId); 
    }
  };

  const AllTemplateName = (values) => {
    let companyIdToUse;
    if (user?.role === UserRole.AltisAdmin) {
      companyIdToUse = values;
    } else {
      companyIdToUse = user?.companyId;
      }
    HTTPService.GET(`/Template/allTemplateName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ITemplatesName[] = response.data;
        tmp.map((elem, index) => {
          elem.key = index;
        });
        setSelectTemplate(tmp);
      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  };

  const AllPrinterName = (values) => {
    let companyIdToUse;
    if (user?.role === UserRole.AltisAdmin) {
      companyIdToUse = values;
    } else {
      companyIdToUse = user?.companyId;
      }
    HTTPService.GET(`/Printer/allPrinterName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IPrinterData[] = response.data;
        setSelectPrinter(tmp);
      })
      .catch((error) => {
        console.error('Grup adları çekme hatası:', error);
      });
  };

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  const handleUpload = (values) => {
    try {
      const formData = new FormData();
      formData.append('file', values.inputValue.file);
      if (user.role === UserRole.AltisAdmin) {
        formData.append('companyId', values.selectedCompany);
      } else {
        formData.append('companyId', user.companyId.toString());
      }
      formData.append('templateId', values.selectUpload);
      formData.append('printerId', values.selectPrinter);
     HTTPService.POST(`/PrintJob/PrintsJobsUpload`, formData).then((response) => {
       if (response.status === 200) {
         toast.success(t(response.data));
         window.location.reload();
       } else {
         toast.error(t(response.data));
       }
     });
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const handleDownload = () => {
    HTTPService.GETBlob(`/Template/PrintJobDownload`)
      .then((response) => {
        if (!response) {
          return;
        }
        return response;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'template.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('API çağrısında bir hata oluştu:', error);
      });
      setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisibleDetails(false)
  };

  const handleCancelUpload = () => {
    setIsModalVisible(false);
  };

  const uploadProps = {
    onRemove: (file) => {
      setFileList((prevFileList) => {
        const index = prevFileList.indexOf(file);
        const newFileList = prevFileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    multiple: false,
    accept: '.xlsx,.xls',
  };

  const pageData: IPageData = {
    title: t('prints_jobs_excel'),
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TagFilter();
  }, []);

  const TagFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    var body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      printerId: e?.printerId,
      printerName: e?.printerName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      templateId: e?.templateId,
      templateName: e?.templateName,
      inventoryId: e?.inventoryId,
      inventoryName: e?.inventoryName,
      printDate_Start: e?.printDate_Start === null || e?.printDate_Start === "" ? null : new Date(Date.parse(e?.printDate_Start)),
      printDate_Finish: e?.printDate_Finish === null || e?.printDate_Finish === "" ? null : new Date(Date.parse(e?.printDate_Finish)),
      isExcel: true,
    };
    HTTPService.POST(`/PrintJob/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status == 200) {
        setPrintList(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DeletePrintsJobs = (id) => {
    HTTPService.DELETE(`/PrintJob/deletePrintJob?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(t(response.data));
      }
    });
  };

  const ImagePriview = (id) => {
    HTTPService.POST(`/PrintJob/Preview?printJobId=${id}&type=${"image"}`, '')
      .then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          setImageSrc(tmp);
          setIsModalImage(true);
        }
      })
      .catch((error) => {
        console.error('There was an error fetching the image!', error);
      });
  };

  const tagDataColumns: IAltisDataGridColumns[] = [
    ...(user.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      isSorting:true,
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    {
      title: t('template_name'),
      key: 'templateName',
      isSorting:true,
      placeHolder: t('template_name'),
      inputType: 'input',
      width: 1000,
    },
    {
      title: t('barcode'),
      key: 'barcode',
      placeHolder: t('barcode'),
      inputType: 'operations',
      render: (order,record) => {
        const barcodes = record.barcode ? record.barcode.split(',') : [];
        if (barcodes.length > 1) {
          return (
            <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {barcodes[0]}...
            </div>
          );
        }
        return barcodes; 
      },
    },
    {
      title: t('Printer Name'),
      key: 'printerName',
      placeHolder: t('Printer Name'),
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          
          <Button size='small' icon={<EyeOutlined />} style={{display: user.role === UserRole.Sayman ? 'none' : 'block',marginLeft:40}}    onClick={() => {
                 setModalData(record.printJobDetails);
                setIsModalVisibleDetails(true); 
              }} 
            >
              {record.printerName}
          </Button>
        </div>
      ),
    },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
          <Popconfirm
              title={t('are_you_sure_printJob')}
              onConfirm={() => DeletePrintsJobs(record.printJobId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')}>
    
              <Button size='small' icon={<DeleteOutlined />} style={{width: '60px',borderRadius:'70px'}}  onClick={(e) => e.stopPropagation()}>
        
                </Button>
              </Tooltip>
             
            </Popconfirm>
            <Tooltip title={t('preview')}>
            <Button
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '60px',borderRadius:'70px'  }}
            onClick={() => {
              ImagePriview(record.printJobId);
            }}>
          </Button>

            </Tooltip>
          
          {!record.jobStatus && (
          <Tooltip title={t('Reprint')}>
                <Button
              size='small'
              icon={<PrinterOutlined />}
              style={{width: '60px',borderRadius:'70px'}}
              onClick={() => {
                PrintData(record.printJobId)
              }}>
            </Button>

          </Tooltip>
          
          )}
          </Space>
        </div>
      ),
    },
  ];

  const paginatedImages = () => {
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = startIndex + imagesPerPage;
    return imageSrc?.labels?.slice(startIndex, endIndex) || [];
  };

  const totalPages = Math.ceil((imageSrc?.labels?.length || 0) / imagesPerPage);

  const generatePagination = () => {
    const pages = [];
    const showPages = 3; // Her iki tarafta gösterilecek sayfa sayısı

    if (totalPages <= showPages * 2 + 1) {
      // Toplam sayfa sayısı azsa tüm sayfaları göster
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // İlk 3 sayfa ve son 3 sayfa gösterilecek
      for (let i = 1; i <= showPages; i++) {
        pages.push(i);
      }
      if (currentPage > showPages + 2) {
        pages.push('...');
      }
      const startPage = Math.max(1, totalPages - showPages + 1);
      for (let i = startPage; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };


  const PrintData = (id: number) => {
    HTTPService.POST(`/PrintJob/Reprint?printJobId=${id}`, "").then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(t(response.data));
      }

    }).catch((response) => {

      console.error(response);
    })
  };

  const handleImage = () => {
    setIsModalImage(false);
    setImageSrc('');
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    AllTemplateName(value); 
    AllPrinterName(value);
  };
  return (
    <>
      <div className='row'>
        <div className='col-md-4 mb-3' style={{ display: user.role === UserRole.Sayman ? 'none' : 'block' }}>
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20 }}
            size='middle'
            onClick={showModal}>
            {t('upload_labels')}
          </Button>
        </div>
      </div>

      <Modal
        title={t('upload_excel_file')}
        visible={isModalVisible}
        onCancel={handleCancelUpload}
        width={800}
        footer={null}
      >
      <div style={{ padding: '20px' }}>
        <Form onFinish={handleUpload}>
          <div style={{ height: 'auto', overflowY: 'auto', marginTop: '10px' }}>
            <Form.Item name='inputValue'>
              <Upload.Dragger {...uploadProps}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  {t('click_or_drag_file')}
                </p>
                <p className='ant-upload-hint'>
                  {t('support_single_upload')}
                </p>
              </Upload.Dragger>
            </Form.Item>
          </div>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          {user?.role === UserRole.AltisAdmin ? (
            <Form.Item
              name='selectedCompany'
              label={t('Select company')}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: t('please_select_company'),
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('select_company')}
                style={{ width: '100%', marginBottom: '6px' }} // Consistent width
                onChange={handleCompanyChange}
              >
                {companyData?.map((company) => (
                  <Select.Option key={company.companyId} value={company.companyId}>
                    {company.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
        </Col>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={t('select_template')}
            name='selectUpload'
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: t('please_select_template'),
              },
            ]}
          >
            <Select
              key='selectTemplateUpload'
              style={{ width: '100%', marginRight: '10px' }} 
              placeholder= {t('please_select_template')}
            >
              {selectTemplate?.map((item) => (
                <Select.Option key={item.templateId} value={item.templateId}>
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('Select Printer')}
            name='selectPrinter'
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: t('please_select_printer'),
              },
            ]}
          >
            <Select
              key='selectPrinterUpload'
              style={{ width: '100%', marginBottom: '6px' }}
              placeholder= {t('please_select_printer')}
            >
              {selectPrinter?.map((item) => (
                <Select.Option key={item.printerId} value={item.printerId}>
                  {item.printerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16} justify="end">
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item style={{ textAlign: 'right', marginTop: '20px' }}>
                <Button
                  key='upload'
                  type='primary'
                  onClick={handleCancelUpload}
                  icon={<UploadOutlined />}
                  size='middle'
                  htmlType='submit'
                  style={{ width: '100%' }} // Ensure full width on smaller screens
                >
                  {t('excel_file_upload')}
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item style={{ textAlign: 'right', marginTop: '20px' }}>
                <Button
                  key='download'
                  type='primary'
                  onClick={handleDownload}
                  icon={<DownloadOutlined />}
                  size='middle'
                  htmlType='button'
                  style={{ width: '100%' }} // Ensure full width on smaller screens
                >
                  {t('excel_file_download')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  </div>
</Modal>


      <AltisDataGrid
        data={printList?.data}
        total={printList?.count}
        columns={tagDataColumns}
        onChange={TagFilter}
      />

<Modal title={t('image_preview')} visible={isModalImage} onCancel={handleImage} footer={null}>
      {paginatedImages().length > 0 && (
        <Image
          src={`data:image/png;base64,${paginatedImages()[0].imageBase64}`}
          style={{ width: 'auto', height: 'auto' }}
          alt='Preview'
        />
      )}
        {totalPages > 1 && (
        <Pagination
          current={currentPage}
          pageSize={imagesPerPage}
          total={imageSrc?.labels?.length}
          onChange={page => setCurrentPage(page)}
          style={{ textAlign: 'center', marginTop: '16px' }}
          itemRender={(page, type, originalElement) => {
            if (type === 'page') {
              const pages = generatePagination();
              if (pages.includes(page) || page.toString() === '...') {
                return <span style={{ padding: '0 8px', cursor: 'pointer', fontWeight: currentPage === page ? 'bold' : 'normal' }}>{page}</span>;
              }
              return originalElement;
            }
            return originalElement;
          }}
        />
      )}
      </Modal>

      <Modal
        title={<span style={{ color: 'red' }}>{t('details')}</span>}
        visible={isModalVisibleDetails}
        bodyStyle={{ width: 510, height: 350, overflowY: 'auto' }} // Kaydırma çubuğu için ekleme
        footer={null}
        onCancel={handleCancel}
      >
       <div>
          {modalData ? modalData.split(',').map((item, index) => (
            <p key={index} style={{ margin: 0 }}>
              {item.trim()}
            </p>
          )) : (
            <p>{t('noDetails')}</p> // Ya da başka bir varsayılan mesaj
          )}
        </div>
      </Modal>
    </>
  );
};

export default PrintsJobsExcel;
