import { Button, Card, Form, Input } from "antd";
import { toast } from "react-hot-toast";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import { IPageProps } from "../../../interfaces/page-data";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication } from "../../../interfaces/authentication";
import { t } from "i18next";



const EmailChange: React.FunctionComponent<IPageProps> = () => {
    const loader = new StateLoader();
    const authInfo: IAuthentication = loader.loadAuthState()



    const update = (e) => {


        HTTPService.PUT(`/User/UpdateEmail?email=${authInfo.email}&newEmail=${e.newEmail}&password=${e.Password}`, {})
            .then((res) => {
                if (res.status === 200) {
                    toast.success(t(res.data))
                } else if (res.status === 450) {
                    toast.error(t(res.data));
                }
                else {
                    toast.error(t(res.data));
                }
            })
            .catch(error => {
                toast.error('There was an error!', error);
            });
    }
    return (
        <Card style={{ padding: '50px' }}>
            <h3>{t('Change Email')}</h3>
            <Form
                layout='vertical'
                onFinish={update}
            >
                <Form.Item name='newEmail'
                    label={t('email')}
                    rules={[
                        {
                            required: true,
                            message: t('please_input_email'),
                            whitespace: true,
                        },
                        {
                            pattern: /^[^\s]+$/,
                            message: t('emailNotSpaces'),
                        },
                    ]}
                >
                    <Input
                        prefix={<MailOutlined />}
                        placeholder={t('email')}
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item name='Password'
                    label={t('password')}
                    rules={[{ required: true, message: t('please_input_your_password') }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder={t('password')}
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item>
                    <div className='span d-flex justify-content-between'>
                        <span />
                        <Button htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                            {t('save')}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    );
}
export default EmailChange;