import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Input, Space} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import HTTPService from "../../utils/makerequest";
import {useDispatch} from "react-redux";

import { StateLoader } from '../../redux/StateLoader';
import toast from 'react-hot-toast';
import { UserRole } from '../../interfaces/authentication';
import '../../i18n';
import { t } from 'i18next';


const SingInForm: React.FC<any> = props => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const loader = new StateLoader();
    const handleClose = () => {
        setVisible(false);
    };
    

    const login = (e) => {
        let user = {email: e.email, pass: e.password};
        HTTPService.LOGIN(`/Login`, user)
        .then( (response) => {
            
            //auth.adminID = encrypt(auth.adminID, SALT);
            if(response.status === 200)
            {
                if (response.data.role === UserRole.Sayman) {
                    toast.error(t('authority'));
                    return;
                }
                loader.saveAuthState( response.data)
                toast.success(t('login'));
                window.location.replace('/admin/dashboard/');
            }
            else
            {
                toast.error(response.data);
            }
            
        })
        .catch((response) => {
            setVisible(true);
            return;
        });
    }
    
    return (
        <Form style={{width: '100%'}} onFinish={login}>
            {visible && (<Space direction="vertical" style={{ width: '100%', marginBottom: '10px' }}>
                    <Alert message="email or password wrong!" type="error" closable afterClose={handleClose} />
                </Space>
            )}
            <Form.Item name='email'
                       rules={[{required: true, message: t('please_input_email'), whitespace: true}]}>
                <Input
                    prefix={<UserOutlined/>}
                    placeholder={t('email')}
                    style={{ borderRadius: 0 }}
                />
            </Form.Item>
            <Form.Item name='password'
                       rules={[{required: true, message: t('inputPassword')}]}>
                <Input.Password
                 prefix={<LockOutlined/>} 
                 placeholder={t('password')}
                style={{ borderRadius: 0 }}/>
            </Form.Item>
            <Form.Item>
                <div className='span d-flex flex-column align-items-center'>
                    <span/>
                    <Button htmlType="submit" type="primary" style={{ width: '60%', marginBottom: '15px',borderRadius: 0 }}>
                       {t('sign')}
                    </Button>
                    <Button htmlType="submit" type="primary" style={{ width: '60%',borderRadius: 0 }}
                    onClick={() => {
                        window.location.replace('/public/forgot-password/');}
                    }>
                       {t('forgot')}
                        </Button>
                </div>
            </Form.Item>
            
        </Form>
    );
};

//const WrappedForm = Form.create({ name: 'sign-in' })(SingInForm);

export default SingInForm;