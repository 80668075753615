import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { t } from "i18next";
import { IDepartmentData } from "../../../interfaces/IDepartmentData";
import { IPageProps } from "../../../interfaces/page-data";



const UpdateDepartment: React.FunctionComponent<IPageProps> = () => {
  const { departmentId } = useParams<any>();
  const [departmentData, setDepartmentData] = useState<IDepartmentData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const [form] = Form.useForm();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    fetchCompanyData();
  }, []);

  useEffect(() => {
    if (departmentId != null) {
      getDepartmentData(departmentId);
    } else if (departmentData?.departmentId) {
      getDepartmentData(departmentData.departmentId);
    }
  }, [departmentId, departmentData?.departmentId]);


  const fetchCompanyData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error('Error fetching company data:', error);
      toast.error('Failed to load company data.');

    }
  };

  const getDepartmentData = (departmentId: number) => {
    HTTPService.GET(`/Department/ById?departmentId=${departmentId}`)
      .then((res) => {
        if (res.status === 200) {
          const data: IDepartmentData = res.data;
          setDepartmentData(data);
          form.setFieldsValue({
            departmentName: data.departmentName,
            companyId: data.companyId
          });
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching department data:', error);
        toast.error('Failed to load department data.');
      });
  };


  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };


  const updateDepartment = (formData: any) => {
    const updatedDepartment = {
      departmentId: departmentId,
      departmentName: formData.departmentName,
      companyId: formData.companyId || userInfo.companyId,
    };

    HTTPService.PUT(`/Department/updateDepartment`, updatedDepartment)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t(res.data));

          window.location.replace("/admin/department-management");

        } else if (res.status === 450) {
          toast.error(t(res.data));
        }
        else {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error('There was an error updating the department.');
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/department-management/");
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={updateDepartment}
      fields={[
        { name: ["companyId"], value: departmentData?.companyId || userInfo.companyId },
        { name: ["departmentName"], value: departmentData?.departmentName },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_department')}</h3>
        {userInfo?.role === UserRole.AltisAdmin && (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[{ required: true, message: 'Please select a company!' }]}
          >
            <Select
              showSearch
              placeholder={t('company_name')}
              style={{ marginBottom: '6px' }}
              filterOption={filterOption}
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="departmentName"
              label={t('department_name')}
              rules={[{ required: true, message: "Please input your Department Name!", whitespace: true }]}
              style={{ marginBottom: 0 }} // Remove margin if needed
            >
              <Input
                style={{ borderRadius: 0, width: '100%' }} // Set width to 100% for responsiveness
                placeholder={t('department_name')}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateDepartment;
